export interface NavItemType {
  label: string;
  children?: Array<NavItemType>;
  code?: string;
}

export const NAV_LANG_ITEMS: Array<NavItemType> = [
  {
    label: "English",
    children: [
      { label: "Nederlands", code: "nl" },
      { label: "Français", code: "fr" },
      { label: "Deutsch", code: "de" },
      { label: "English", code: "en" },
      { label: "Español", code: "es" },
      { label: "Italiano", code: "it" },
      { label: "Dansk", code: "da" },
      { label: "Magyar", code: "hu" },
      { label: "Norsk", code: "no" },
      { label: "Polski", code: "pl" },
      { label: "Português", code: "pt" },
      { label: "Suomi", code: "fi" },
      { label: "Svenska", code: "sv" },
    ],
  },
];
