import { useQuery } from "@apollo/client";
import React from "react";
import { GET_PRODUCTS_BY_ARRAY_IDS } from "../Apollo/typedefs";
import { decryptObject } from "../utils/crypt";

const useProductReservation = (challenge: any) => {
  const [_challenge] = React.useState(challenge);
  const { data, error, loading } = useQuery(GET_PRODUCTS_BY_ARRAY_IDS, {
    variables: { challenge: _challenge },
  });

  const [products, set_products] = React.useState("");

  React.useEffect(() => {
    async function fetchIt() {
      if (!loading && !error && data && data.getProductsByArrayIds)
        await set_products(decryptObject(data.getProductsByArrayIds));
    }
    fetchIt();
  }, [data, error, loading]);

  return products;
};

export default useProductReservation;
