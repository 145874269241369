import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";

import { Box, Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { CANCEL_RESERVATION } from "../../../../Apollo/typedefs";
import useMessages from "../../../../hooks/useMessages";
import { capitalizeFirstLetter } from "../../../../lib/string";
import { decryptObject, encryptObject } from "../../../../utils/crypt";
import { useToast } from "@chakra-ui/toast";
import { config } from "../../../../config";

interface CancelReservationContainerProps {
  reservation: any;
}

const CancelReservationContainer: React.FC<CancelReservationContainerProps> = ({
  reservation,
}) => {
  const toast = useToast();

  const _messages: any = useMessages(`/lang/pages/reservate/actions/cancel/`);

  // console.log(reservation);

  const [cancel, { loading: cancelLoading }] = useMutation(CANCEL_RESERVATION);

  const colorText = useColorModeValue("white", "gray.200");

  const colorBGC1 = useColorModeValue("blue.600", "blue.900");
  const colorBGC2 = useColorModeValue("blue.300", "blue.600");
  const [check, set_check] = React.useState(false);

  if (_messages === "") {
    return <span>Loading...</span>;
  } else {
    const messages = JSON.parse(_messages);

    return (
      <Box>
        <Box
          px="2vw"
          py="7.5vh"
          bgGradient={`linear(to-r, ${colorBGC1}, ${colorBGC2})`}
          color={colorText}
          fontSize="5xl"
          fontWeight="bold"
        >
          {capitalizeFirstLetter(messages["cancel_your_reservation"])}
        </Box>

        <Flex h="64px"></Flex>

        <Flex justifyContent="center" alignItems="center" w="100%" bg="white">
          <Flex direction="column" w="3xl">
            <Flex
              direction="column"
              px="16px"
              py="32px"
              borderWidth="1px"
              boxShadow="sm"
              mt="16px"
              bg="gray.50"
            >
              <Flex direction="column" w="100%">
                <Flex
                  style={{ cursor: "pointer" }}
                  as="label"
                  htmlFor="gc-check"
                  mb="3"
                >
                  <Checkbox
                    checked={check}
                    onChange={(e) => {
                      set_check(e.target.checked);
                    }}
                    mr={document.body.style.direction === "rtl" ? "0" : "3"}
                    ml={document.body.style.direction !== "rtl" ? "0" : "3"}
                    id="gc-check"
                  />{" "}
                  <Text pr="3">
                    {capitalizeFirstLetter(
                      messages["i_agree_to_cancel_the_reservation"]
                    )}
                  </Text>
                  <b>#{reservation.num_ticket}</b>
                </Flex>
                <Flex
                  className="display-print-none"
                  alignItems="end"
                  justifyContent="end"
                >
                  <Button
                    disabled={!check || cancelLoading}
                    onClick={async () => {
                      if (!cancelLoading) {
                        const { data } = await cancel({
                          variables: {
                            challenge: encryptObject({ id: reservation.id }),
                          },
                        });
                        if (!data) {
                          // console.log("something went wrong 01");
                        }
                        // console.log(data);

                        const { msg } = decryptObject(data.cancelReservation);
                        if (!msg) {
                          // console.log("something went wrong 02");
                        }

                        if (msg === "canceled") {
                          toast({
                            title: capitalizeFirstLetter(
                              messages["your_reservation_has_been_cancelled"]
                            ),
                            description: capitalizeFirstLetter(
                              messages["weve_canceled_your_reservation"]
                            ),
                            status: "info",
                            duration: 3000,
                            isClosable: true,
                          });
                          setTimeout(() => {
                            window.location.href = config.APP_HOME_URL;
                          }, 2500);
                        } else {
                          toast({
                            title: capitalizeFirstLetter(
                              messages["something_was_wrong"]
                            ),
                            description: `${msg}`,
                            status: "error",
                            isClosable: true,
                          });
                        }
                      }
                    }}
                    px="32px"
                    colorScheme="brand"
                  >
                    {capitalizeFirstLetter(messages["cancel"])}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex h="64px"></Flex>
      </Box>
    );
  }
};

export default CancelReservationContainer;
