import React, { useState } from "react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Box,
} from "@chakra-ui/react";

interface TimePickerProps {
  reff?: string;
  defaultValue?: string; // Optional default time in HH:MM format
  onChange?: (time: string) => void; // Callback for time changes
  disabled?: boolean; // Disables the component (optional)
}

const TimePicker: React.FC<TimePickerProps> = ({
  defaultValue = "00:00",
  onChange,
  disabled,
}) => {
  const [hour, setHour] = useState(parseInt(defaultValue.split(":")[0]));
  const [minute, setMinute] = useState(
    Math.round((parseInt(defaultValue.split(":")[1]) % 60) / 15) * 15
  );

  const handleHourChange = (newHour: number) => {
    setHour(newHour);
    if (onChange) {
      onChange(
        `${newHour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  };

  const handleMinuteChange = (newMinute: number) => {
    setMinute(newMinute * 15);
    if (onChange) {
      onChange(
        `${hour.toString().padStart(2, "0")}:${(newMinute*15)
          .toString()
          .padStart(2, "0")}`
      );
    }
  };

  return (
    // style={`${disabled ? "pointer-event: none; opacity: 0.5;": ""}`}

    <Flex
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      marginTop={"24px"}
      marginBottom={"16px"}
    >
      <Flex width="48px" marginRight="16px">
        <Box>{hour === 0 ? "00" : hour}</Box>
        <Box>&nbsp;:&nbsp;</Box>
        <Box>{minute === 0 ? "00" : minute}</Box>
      </Flex>

      <Flex
        width={"150px"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Flex width={"100%"} justifyContent={"space-between"}>
          <Box width="56px" paddingRight="24px">
            HH
          </Box>
          <Slider
            aria-label="hour"
            value={hour}
            min={0}
            max={23}
            step={1}
            onChange={handleHourChange}
            isDisabled={disabled}
          >
            <SliderTrack>
              <SliderFilledTrack bg="blue.300" />
            </SliderTrack>
            <SliderThumb
              bg="blue.300"
              boxShadow="0 1px 6px rgba(0, 0, 0, 0.1)"
            />
          </Slider>
        </Flex>

        <Flex width={"100%"} justifyContent={"space-between"}>
          <Box width="56px" paddingRight="24px">
            mm
          </Box>
          <Slider
            aria-label="minute"
            value={minute / 15}
            min={0}
            max={3}
            step={1}
            onChange={handleMinuteChange}
            isDisabled={disabled}
          >
            <SliderTrack>
              <SliderFilledTrack bg="blue.300" />
            </SliderTrack>
            <SliderThumb
              bg="blue.300"
              boxShadow="0 1px 6px rgba(0, 0, 0, 0.1)"
            />
          </Slider>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TimePicker;
