import React from "react";
import { useParams } from "react-router";
import ActionsPageContainer from ".";
import { config } from "../../../config";
import useReservation from "../../../hooks/useReservation";
import { decryptObject, encryptObject } from "../../../utils/crypt";

const ActionsRouterPageContainer: React.FC = () => {
  const { _challenge } = useParams();

  // console.log(_challenge);

  if (!_challenge || _challenge.length < 2) {
    window.location.href = config.APP_HOME_URL;
    return <span>redirect to home</span>;
  }

  const { id, action } = decryptObject(_challenge);

  if (!id || !action) {
    window.location.href = config.APP_HOME_URL;
    return <span>redirect to home</span>;
  }

  return <ActionsRouterPage xprops={{ id, action }} />;
};

export default ActionsRouterPageContainer;

interface actionsRouterPageProps {
  xprops: any;
}

const ActionsRouterPage: React.FC<actionsRouterPageProps> = ({
  xprops,
}: any) => {
  const { id, action } = xprops;
  const reservation = useReservation(encryptObject({ id }));

  if (reservation !== "") {
    return <ActionsPageContainer reservation={reservation} action={action} />;
  } else {
    return <span></span>;
  }
};
