import { useQuery } from "@apollo/client";
import React from "react";
import { GET_RESERVATION_BY_ID } from "../Apollo/typedefs";
import { decryptObject } from "../utils/crypt";

const useReservation = (challenge: any) => {
  const [_challenge] = React.useState(challenge);
  const { data, error, loading } = useQuery(GET_RESERVATION_BY_ID, {
    variables: { challenge: _challenge },
  });

  const [reservation, set_reservation] = React.useState("");

  React.useEffect(() => {
    async function fetchIt() {
      if (!loading && !error && data && data.getReservationById)
        await set_reservation(decryptObject(data.getReservationById));
    }
    fetchIt();
  }, [data, error, loading]);

  return reservation;
};

export default useReservation;
