import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ColorModeScript } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import client from "../Apollo/client";

import AppLayout from "./Layout";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router";
import { theme } from "../theme";
import "../theme/index.css";
import ReservateLangContainer from "../pages/Reservate/containers/lang";
import ActionsRouterPageContainer from "../pages/Reservate/actions/router";

export const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <ColorModeScript />
        <BrowserRouter>
          <AppLayout>
            <Routes>
              <Route
                path="/a/:_challenge"
                element={<ActionsRouterPageContainer />}
              />
              <Route path="/" element={<ReservateLangContainer />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </AppLayout>
        </BrowserRouter>
      </ChakraProvider>
    </ApolloProvider>
  );
};
