import React from "react";
import { Flex, Link } from "@chakra-ui/layout";

const FrontError: React.FC = () => {
  return (
    <Flex
      boxShadow="0px 0px 3px 1px rgba(0,0,0,0.3)"
      direction="column"
      px="16px"
      py="32px"
      mx="32px"
      mt="128px"
      mb="256px"
    >
      <b>We are currently in maintenance mode please check back shortly...</b>
      <br />
      <Link color="brand.500" _focus={{ boxShadow: "none" }} href="/">
        Try to refresh the application
      </Link>
    </Flex>
  );
};

export default FrontError;
