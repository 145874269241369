import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link, Stack, Box, Flex, Text } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React from "react";
import { Link as ReachLink } from "react-router-dom";
import { NavItemType } from "../../Lang/NAV_LANG_ITEMS";

const DesktopSubNav = ({ label, code }: NavItemType) => {
  return (
    <Link
      as={ReachLink}
      to={`?lang=${code}`}
      role={"group"}
      display={"block"}
      p={1}
      rounded="3px"
      _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "white" }}
            fontWeight={500}
          >
            {label}
          </Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"blue.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

export default DesktopSubNav;
