import React from "react";
import PhonesSelectComponent from ".";
import useMessages from "../../hooks/useMessages";

interface PhonesSelectContainerProps {
    defaultValueCode: string;
  set_: React.Dispatch<React.SetStateAction<string>>;
}

const PhonesSelectContainer: React.FC<PhonesSelectContainerProps> = ({
    defaultValueCode,
  set_,
}) => {
  const messages = useMessages(`/lang/components/phones/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return (
      <PhonesSelectComponent
        set_={set_}
        defaultValueCode={defaultValueCode}
        phones={JSON.parse(messages)}
      />
    );
  }
};

export default PhonesSelectContainer;
