import { useQuery } from "@apollo/client";
import React from "react";
import { SkeletonCircle, SkeletonText } from "@chakra-ui/skeleton";

import ReservateComponent from "..";
import { EDIT_GDDWAD_QUERY } from "../../../Apollo/typedefs";
import NProgressComponent from "../../../App/NProgress";
import FrontError from "../../../Components/FrontError";
import { decryptObject, encryptObject } from "../../../utils/crypt";
import { Box } from "@chakra-ui/layout";

interface indexProps {
  messages: any;
  reservation?: any;
}

const EditReservationContainer: React.FC<indexProps> = ({
  messages,
  reservation,
}) => {
  // get_disabled_dates_with_available_day
  // GDDWAD
  const [challenge] = React.useState(
    encryptObject({
      id_price: reservation.id_price,
      min: reservation.start_date_int,
    })
  );
  const {
    data: GDDWAD_DATA,
    error: GDDWAD_ERROR,
    loading: GDDWAD_LOADING,
  } = useQuery(EDIT_GDDWAD_QUERY, {
    variables: {
      challenge,
    },
  });

  if (GDDWAD_ERROR) {
    return <FrontError />;
  }

  if (GDDWAD_LOADING) {
    return (
      <>
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={14} spacing="4" />
        </Box>
        <NProgressComponent />
      </>
    );
  }

  if (GDDWAD_DATA && GDDWAD_DATA.editGddwad) {
    const { blocked_dates, first_enabled_date, max_enabled_date, products } =
      decryptObject(GDDWAD_DATA.editGddwad);

    if (blocked_dates === undefined) {
      return <FrontError />;
    }
    if (messages !== "") {
      return (
        <ReservateComponent
          extra_products={products}
          first_enabled_date={first_enabled_date}
          blocked_dates={blocked_dates}
          max_enabled_date={max_enabled_date}
          id_price={reservation.id_price}
          messages={messages}
          reservation={reservation}
        />
      );
    } else {
      return <div>loading...</div>;
    }
  } else {
    return <FrontError />;
  }
};

export default EditReservationContainer;
