import { Select } from "@chakra-ui/select";
import React from "react";

interface PhonesSelectComponentProps {
  phones: any;
  defaultValueCode: string;
  set_: React.Dispatch<React.SetStateAction<string>>;
}

const PhonesSelectComponent: React.FC<PhonesSelectComponentProps> = ({
  phones,
  defaultValueCode,
  set_,
}) => {
  return (
    <Select
      defaultValue={defaultValueCode}
      onChange={(e) => set_(e.target.value)}
      size="sm"
      style={{ direction: "ltr" }}
    >
      {phones.map((phone: any) => (
        <option key={phone.code} value={phone.code}>
          {phone.dial_code}{" "}{phone.name}
        </option>
      ))}
    </Select>
  );
};

export default PhonesSelectComponent;
