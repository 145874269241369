import { gql } from "@apollo/client";

export const GET_PRICE_QUERY = gql`
  query GET_PRICE_QUERY($challenge: String!) {
    getPrice(challenge: $challenge)
  }
`;

export const GET_RESERVATION_BY_ID = gql`
  query GET_RESERVATION_BY_ID($challenge: String!) {
    getReservationById(challenge: $challenge)
  }
`;

export const GET_PRODUCTS_BY_ARRAY_IDS = gql`
  query GET_PRODUCTS_BY_ARRAY_IDS($challenge: String!) {
    getProductsByArrayIds(challenge: $challenge)
  }
`;

export const GDDWAD_QUERY = gql`
  query GDDWAD_QUERY($challenge: String!) {
    GDDWAD(challenge: $challenge)
  }
`;
export const EDIT_GDDWAD_QUERY = gql`
  query EDIT_GDDWAD_QUERY($challenge: String!) {
    editGddwad(challenge: $challenge)
  }
`;

export const RESERVATE = gql`
  mutation RESERVATE($challenge: String!) {
    reservate(challenge: $challenge)
  }
`;


export const CANCEL_RESERVATION = gql`
  mutation CANCEL_RESERVATION($challenge: String!) {
    cancelReservation(challenge: $challenge)
  }
`;
