import {
  Box,
  Flex,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";



import React from "react";
import { capitalizeFirstLetter } from "../../../lib/string";

interface FooterProps {
  messages: any;
}


const Footer: React.FC<FooterProps> = ({ messages }) => {
  return (
    <Box
      className="display-print-none"
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Flex>
            <a
              target="_blank"
              rel="noreferrer noopener nofollow"
              href="https://www.develiorate.com/"
            >
              <img src={`/img/develiorate.png`} alt="develiorate.com" />
            </a>
          </Flex>
        </Flex>
        <Box
          bg={useColorModeValue("gray.50", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
        >
          <Container
            as={Stack}
            py={4}
            direction={{ base: "column", md: "row" }}
            spacing={4}
            justify={{ base: "center", md: "center" }}
            align={{ base: "center", md: "center" }}
          >
            <Text w="100%">
              <strong>{capitalizeFirstLetter("Aeropark1000")} </strong>© 2017 -{" "}
              {new Date().getFullYear()} Made By{" "}
              <strong style={{ color: "#339cf0" }}>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://www.develiorate.com/"
                >
                  Develiorate
                </a>
              </strong>
              <br />
              <strong>TVA. BE0680 514 980</strong><br />
              {capitalizeFirstLetter(
                messages["all_rights_reserved"] || "All rights reserved"
              )}
            </Text>
           
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode;
//   label: string;
//   href: string;
// }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
//       rounded={"full"}
//       w={8}
//       h={8}
//       cursor={"pointer"}
//       as={"a"}
//       href={href}
//       target="_blank"
//       display={"inline-flex"}
//       alignItems={"center"}
//       justifyContent={"center"}
//       transition={"background 0.3s ease"}
//       mx="10px"
//       my="5px"
//       _hover={{
//         bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
//       }}
//     >
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };
