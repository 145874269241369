import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import useMessages from "../../../../hooks/useMessages";
import { capitalizeFirstLetter } from "../../../../lib/string";
import DisplayProductsByID from "./DisplayProductsByID";
import useLang from "../../../../hooks/useLang";

interface PrintReservationContainerProps {
  reservation: any;
}

const PrintReservationContainer: React.FC<PrintReservationContainerProps> = ({
  reservation,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);
  const _messages: any = useMessages(`/lang/pages/reservate/actions/print/`);

  // console.log(reservation);

  const colorText = useColorModeValue("white", "gray.200");

  const colorBGC1 = useColorModeValue("blue.600", "blue.900");
  const colorBGC2 = useColorModeValue("blue.300", "blue.600");

  if (_messages === "") {
    return <span>Loading...</span>;
  } else {
    const messages = JSON.parse(_messages);

    return (
      <Box>
        <Box
          px="2vw"
          py="7.5vh"
          bgGradient={`linear(to-r, ${colorBGC1}, ${colorBGC2})`}
          color={colorText}
          fontSize="5xl"
          fontWeight="bold"
          className="display-print-none"
        >
          {capitalizeFirstLetter(messages["print_your_reservation"])}
          <Heading size="sm" color="gray.200">
            {capitalizeFirstLetter(messages["welcome_back"])} {`, `}
            <strong style={{ fontWeight: "bolder" }}>
              {reservation.full_name}
            </strong>
          </Heading>
        </Box>

        <Flex h="64px" className="display-print-none"></Flex>

        <Flex justifyContent="center" alignItems="center" w="100%" bg="white">
          <Flex direction="column" w="3xl">
            <Heading
              className="display-print-none"
              fontWeight="light"
              size="sm"
            >
              {
                messages[
                  "your_reservation_is_still_in_our_system_and_has_been_successfully_completed"
                ]
              }
              <br />
              {
                messages[
                  "this_car_park_has_received_an_excellent_rating_from_our_customers"
                ]
              }
              <br />
              {messages["we_have_informed"]}{" "}
              <span style={{ fontWeight: "bold" }}>Aeropark1000</span>{" "}
              {messages["of_your_reservation"]}{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(reservation.start_date_int, "YYYYMMDD").locale("en").format(
                  "dddd DD MMMM YYYY"
                )}
              </span>
              , {messages["and_they_will_be_ready_to_welcome_you"]}.
            </Heading>
            <Flex
              direction="column"
              p="8px"
              borderWidth="1px"
              boxShadow="sm"
              mt="16px"
              bg="gray.50"
            >
              <Flex
                className="display-print-none"
                alignItems="end"
                justifyContent="end"
              >
                <Button
                  onClick={() => window.print()}
                  px="32px"
                  colorScheme="brand"
                >
                  {capitalizeFirstLetter(messages["print"])}
                </Button>
              </Flex>
              <Flex direction="column" w="100%">
                <Flex mb="32px" w="100%">
                  <Heading w="50%"># {reservation["num_ticket"]}</Heading>
                </Flex>

                {[
                  "full_name",
                  "email",
                  "full_phone_number",
                  "make_and_model",
                  "license_plate_number",
                  "number_of_people",
                  "flight_number_at_start",
                  "start_date",
                  "start_time",
                  "flight_number_at_end",
                  "end_date",
                  "end_time",
                ].map((el, idx: number) => {
                  return (
                    <Flex
                      key={idx + "dyn-elems"}
                      mb="16px"
                      w="100%"
                      borderBottom="1px dotted rgb(206, 206, 206);"
                    >
                      <Text w="50%">{capitalizeFirstLetter(messages[el])}</Text>
                      <Text w="50%">
                        {el === "start_date" || el === "end_date"
                          ? moment(reservation[el], "YYYYMMDD").locale("en").format(
                              "dddd DD MMMM YYYY"
                            )
                          : reservation[el]}
                      </Text>
                    </Flex>
                  );
                })}

                {reservation &&
                  reservation.travel_for === "business" &&
                  [
                    "company_name",
                    "address",
                    "address_two",
                    "zip_code",
                    "city",
                    "vat_number",
                  ].map((el, idx: number) => {
                    return (
                      <Flex
                        key={idx + "dyn-elems"}
                        mb="16px"
                        w="100%"
                        borderBottom="1px dotted rgb(206, 206, 206);"
                      >
                        <Text w="50%">
                          {capitalizeFirstLetter(messages[el])}
                        </Text>
                        <Text w="50%">
                          {el === "start_date" || el === "end_date"
                            ? moment(reservation[el], "YYYYMMDD").locale("en").format(
                                "dddd DD MMMM YYYY"
                              )
                            : reservation[el]}
                        </Text>
                      </Flex>
                    );
                  })}

                <Flex>
                  <DisplayProductsByID
                    reservation_price={reservation.reservation_price}
                    days_in_parking={reservation.days_in_parking}
                    total_price={reservation.total_price}
                    adds_products_price={reservation.adds_products_price}
                    number_of_people={reservation.number_of_people}
                    array_IDS={JSON.parse(reservation.id_products)}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Text
          display="none"
          className="display-print-block"
          textAlign="center"
          fontWeight="bold"
        >
          Rue de Verdun 679, 1130 Bruxelles | aeropark1000@gmail.com | +32 484
          28 08 03
        </Text>
        <Flex h="64px" className="display-print-none"></Flex>
      </Box>
    );
  }
};

export default PrintReservationContainer;
