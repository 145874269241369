import React from "react";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";
import useLang from "../../../hooks/useLang";

import { CANCELED } from "../../../utils/constants";
import ReservateLangContainer from "../containers/lang";
import PrintReservationContainer from "./print";
import ReservationMessage from "./MessageReservation";
import { Flex } from "@chakra-ui/layout";
import CancelReservationContainer from "./cancel";
import { Button } from "@chakra-ui/button";
import useMessages from "../../../hooks/useMessages";
import { capitalizeFirstLetter } from "../../../lib/string";
import { config } from "../../../config";
import { useLocation } from "react-router";

interface actionsPageContainerprops {
  action: string;
  reservation: any;
}

const ActionsPageContainer: React.FC<actionsPageContainerprops> = ({
  action,
  reservation,
}) => {
  const location = useLocation();

  let isManager = false;

  if (location.search) {
    const params = new URLSearchParams(location.search);
    const tmp: any = params.get("manageR");
    if (typeof tmp === "string") {
      isManager = true;
    }
  }

  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);
  const _messages: any = useMessages(`/lang/pages/reservate/actions/`);

  if (_messages === "") {
    return <span>Loading...</span>;
  } else {
    const messages = JSON.parse(_messages);
    const _reservation = reservation.reservation;
    // console.log(_reservation);

    if (isManager) {
      switch (action) {
        case "print":
          return <PrintReservationContainer reservation={_reservation} />;

        case "cancel":
          return <CancelReservationContainer reservation={_reservation} />;

        case "edit":
          return <ReservateLangContainer reservation={_reservation} />;
      }
    }

    if (_reservation.status === CANCELED) {
      return (
        <ReservationMessage>
          <p>
            {" "}
            {messages["your_reservation_is_canceled_on"]}{" "}
            <b>
              {moment(_reservation.updatedAt)
                .locale("en")
                .format("dddd DD MMMM YYYY")}
            </b>
          </p>
          <br />
          <ReservateNewOne messages={messages} />
        </ReservationMessage>
      );
    }

    if (action === "cancel" || action === "edit") {
      if (
        _reservation.start_date_int <= moment().locale("en").format("YYYYMMDD")
      ) {
        return (
          <ReservationMessage>
            <b>
              {messages["your_reservation_is_completed_on"]}{" "}
              {moment(_reservation.updatedAt)
                .locale("en")
                .format("dddd DD MMMM YYYY")}
            </b>
            <br />
            <ReservateNewOne messages={messages} />
          </ReservationMessage>
        );
      }

      if (
        +_reservation.start_date_int <=
        +moment().locale("en").format("YYYYMMDD")
      ) {
        return (
          <ReservationMessage>
            <b>
              {" "}
              {action === "edit"
                ? capitalizeFirstLetter(
                    messages["you_cannot_edit_this_booking"]
                  )
                : capitalizeFirstLetter(
                    messages["you_cannot_cancel_this_booking"]
                  )}{" "}
            </b>
            <br />
            <ReservateNewOne messages={messages} />
          </ReservationMessage>
        );
      }
    }

    switch (action) {
      case "print":
        return <PrintReservationContainer reservation={_reservation} />;

      case "cancel":
        return <CancelReservationContainer reservation={_reservation} />;

      case "edit":
        return <ReservateLangContainer reservation={_reservation} />;
    }
  }

  return <span>challenge</span>;
};

export default ActionsPageContainer;

export const ReservateNewOne = ({ messages }: any) => (
  <Flex alignItems="end" justifyContent="end">
    <Button
      onClick={() => (window.location.href = config.APP_HOME_URL)}
      colorScheme="brand"
      _focus={{ boxShadow: "none" }}
    >
      {capitalizeFirstLetter(messages["reservate_new_one"])}
    </Button>
  </Flex>
);
