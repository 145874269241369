import React from "react";
import { Flex } from "@chakra-ui/layout";

const ReservationMessage: React.FC = ({ children }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Flex
        w="3xl"
        boxShadow="0px 0px 3px 1px rgba(0,0,0,0.3)"
        direction="column"
        px="16px"
        py="32px"
        mx="32px"
        mt="128px"
        mb="256px"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default ReservationMessage;
