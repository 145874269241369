import React from "react";
import Footer from "..";
import useMessages from "../../../../hooks/useMessages";

const FooterLangContainer = () => {
  const messages = useMessages(`/lang/layout/footer/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <Footer messages={JSON.parse(messages)} />;
  }
};

export default FooterLangContainer;
