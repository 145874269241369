import React from "react";
import ReservateContainer from ".";
import useMessages from "../../../hooks/useMessages";
import EditReservationContainer from "./edit";

interface reservateLangContainerProps {
  reservation?: any;
}
const ReservateLangContainer: React.FC<reservateLangContainerProps> = ({
  reservation,
}) => {
  const messages = useMessages(`/lang/pages/reservate/`);

  // console.log("[Reservate] messages=>", messages);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    if (reservation) {
      return (
        <EditReservationContainer
          messages={JSON.parse(messages)}
          reservation={reservation}
        />
      );
    } else {
      return (
        <ReservateContainer
          messages={JSON.parse(messages)}
        />
      );
    }
  }
};

export default ReservateLangContainer;
