import { Flex, Spacer } from "@chakra-ui/layout";
import React from "react";
import useMessages from "../../../../../hooks/useMessages";
import { capitalizeFirstLetter } from "../../../../../lib/string";
import ExtraProducts from "./ExtraProducts";

interface DisplayProductsByIDProps {
  reservation_price: string;
  days_in_parking: number;
  total_price: number;
  adds_products_price: number;
  number_of_people: number;
  array_IDS: any;
}

const DisplayProductsByID: React.FC<DisplayProductsByIDProps> = ({
  reservation_price,
  days_in_parking,
  total_price,
  adds_products_price,
  number_of_people,
  array_IDS,
}) => {
  const _messages: any = useMessages(
    `/lang/pages/reservate/actions/print/DisplayProductsByID/`
  );

  if (_messages === "") {
    return <span>Loading...</span>;
  } else {
    const messages = JSON.parse(_messages);

    return (
      <Flex
        direction="column"
        w="100%"
        bg="gray.100"
        m="16px"
        p="8px"
        boxShadow="xs"
      >
        <Flex py="8px">
          <Flex>
            {days_in_parking}{" "}
            {days_in_parking > 1 ? messages["days"] : messages["day"]} (
            {messages["free_shuttle_service"]})
          </Flex>
          <Spacer
            mx="16px"
            style={{ borderBottom: "1.5px dotted rgb(206, 206, 206)" }}
          />
          <Flex>{reservation_price} €</Flex>
        </Flex>
        {number_of_people > 4 && (
          <Flex py="8px">
            <Flex>{capitalizeFirstLetter(messages["8_euro_by_passenger_in_addition"])}</Flex>
            <Spacer
              mx="16px"
              style={{ borderBottom: "1.5px dotted rgb(206, 206, 206)" }}
            />
            <Flex>{(number_of_people -4) * 10} €</Flex>
          </Flex>
        )}

        <Flex py="8px">
          <Flex>{capitalizeFirstLetter(messages["cash_payment"])}</Flex>
          <Spacer
            mx="16px"
            style={{ borderBottom: "1.5px dotted rgb(206, 206, 206)" }}
          />
          <Flex></Flex>
        </Flex>

        {adds_products_price > 0 && (
          <ExtraProducts free={capitalizeFirstLetter(messages["free"])} array_IDS={array_IDS} />
        )}
        <Flex
          style={{ borderTop: "2px solid rgb(206, 206, 206)" }}
          mt="16px"
          py="8px"
          pt="16px"
          fontWeight="bold"
        >
          <Flex>{capitalizeFirstLetter(messages["total"])}</Flex>
          <Spacer
            mx="16px"
            style={{ borderBottom: "1.5px dotted rgb(206, 206, 206)" }}
          />
          <Flex>{total_price} €</Flex>
        </Flex>
      </Flex>
    );
  }
};

export default DisplayProductsByID;
