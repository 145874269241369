import React from "react";
import CountriesSelectComponent from ".";
import useMessages from "../../hooks/useMessages";

interface CountriesSelectContainerProps {
    defaultValueCode: string;
  set_: React.Dispatch<React.SetStateAction<string>>;
}

const CountriesSelectContainer: React.FC<CountriesSelectContainerProps> = ({
    defaultValueCode,
  set_,
}) => {
  const messages = useMessages(`/lang/components/countries/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return (
      <CountriesSelectComponent
        set_={set_}
        defaultValueCode={defaultValueCode}
        countries={JSON.parse(messages)}
      />
    );
  }
};

export default CountriesSelectContainer;
