import { useLocation } from "react-router";
import { config } from "../config";

const useLang = () => {
  let lang: string = config.DEFAULT_LANG;
  const location = useLocation();

  if (location.search) {
    const params = new URLSearchParams(location.search);
    const tmp: any = params.get("lang");
    if (typeof tmp === "string") {
      lang = tmp;
    }
  }

  return lang;
};

export default useLang;
