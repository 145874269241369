import React from "react";
import ParkingInfo from "..";
import useMessages from "../../../../../hooks/useMessages";

const ParkingInfoLangContainer = () => {
  const messages = useMessages(`/lang/layout/navbar/parking-info/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <ParkingInfo messages={JSON.parse(messages)} />;
  }
};

export default ParkingInfoLangContainer;
