import { useDisclosure } from "@chakra-ui/hooks";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Stack, Flex, Text, Link } from "@chakra-ui/layout";
import { Icon, Collapse } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React from "react";
import CurrentLangName from "../../CurrentLangName";
import { NavItemType } from "../../Lang/NAV_LANG_ITEMS";

const MobileNavItem = ({ label, children, code }: NavItemType) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        to={``}
        onClick={(e) => {
          e.preventDefault();
        }}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          <CurrentLangName />
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                py={"2"}
                href={`?lang=${child.code}`}
                onClick={() => onToggle()}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNavItem;
