import { Select } from "@chakra-ui/select";
import React from "react";

interface CountriesSelectComponentProps {
  countries: any;
  defaultValueCode: string;
  set_: React.Dispatch<React.SetStateAction<string>>;
}

const CountriesSelectComponent: React.FC<CountriesSelectComponentProps> = ({
  countries,
  defaultValueCode,
  set_,
}) => {
  return (
    <Select
      defaultValue={defaultValueCode}
      onChange={(e) => set_(e.target.value)}
      size="sm"
      style={{ direction: "ltr" }}
    >
      {countries.map((country: any) => (
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      ))}
    </Select>
  );
};

export default CountriesSelectComponent;
