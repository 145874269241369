import { useQuery } from "@apollo/client";
import React from "react";
import { SkeletonCircle, SkeletonText } from "@chakra-ui/skeleton";

import ReservateComponent from "..";
import { GDDWAD_QUERY } from "../../../Apollo/typedefs";
import NProgressComponent from "../../../App/NProgress";
import FrontError from "../../../Components/FrontError";
import { decryptObject, encryptObject } from "../../../utils/crypt";
import { Box } from "@chakra-ui/layout";

const challenge = encryptObject({ pass: "GDDWAD135486468" });

interface indexProps {
  messages: any;
}

const ReservateContainer: React.FC<indexProps> = ({ messages }) => {
  // get_disabled_dates_with_available_day
  // GDDWAD
  const {
    data: GDDWAD_DATA,
    error: GDDWAD_ERROR,
    loading: GDDWAD_LOADING,
  } = useQuery(GDDWAD_QUERY, {
    variables: {
      challenge,
    },
  });

  if (GDDWAD_ERROR) {
    return <FrontError />;
  }

  if (GDDWAD_LOADING) {
    return (
      <>
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={14} spacing="4" />
        </Box>
        <NProgressComponent />
      </>
    );
  }

  if (GDDWAD_DATA && GDDWAD_DATA.GDDWAD) {
    const {
      blocked_dates,
      first_enabled_date,
      max_enabled_date,
      products,
      id_price,
    } = decryptObject(GDDWAD_DATA.GDDWAD);

    if (blocked_dates === undefined) {
      return <FrontError />;
    }
    if (messages !== "") {

      return (
        <ReservateComponent
          extra_products={products}
          first_enabled_date={first_enabled_date}
          blocked_dates={blocked_dates}
          max_enabled_date={max_enabled_date}
          id_price={id_price}
          messages={messages}
        />
      );
    } else {
      return <div>loading...</div>;
    }
  } else {
    return <FrontError />;
  }
};

export default ReservateContainer;
