import { Button } from "@chakra-ui/button";
import { Stack, Flex } from "@chakra-ui/layout";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { useColorModeValue } from "@chakra-ui/system";
import React from "react";
import CurrentLangName from "../CurrentLangName";
import { NAV_LANG_ITEMS } from "../Lang/NAV_LANG_ITEMS";
import DesktopSubNav from "./DesktopSubNav";

const DesktopNav: React.FC = () => {
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_LANG_ITEMS.map((navItem) => (
        <Flex align="center" key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Button
                rounded="3px"
                minW="135px"
                display={{ base: "none", lg: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"blue.500"}
                _hover={{
                  bg: "blue.400",
                }}
              >
                <CurrentLangName />
              </Button>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded="3px"
                roundedTop="0"
                minW={"s"}
                w="3xs"
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Flex>
      ))}
    </Stack>
  );
};

export default DesktopNav;
