import React from "react";
import useLang from "./useLang";

const useMessages = (path: string) => {
  const [messages, set_messages] = React.useState("");

  const lang = useLang();

  React.useEffect(() => {
    async function fetchlang() {
      const response = await fetch(`${path}${lang}.json`);
      // waits until the request completes...
      const _msgs = await response.json();

      await set_messages(JSON.stringify(_msgs));
    }
    fetchlang();
  }, [lang, path]);

  return messages;
};

export default useMessages;
