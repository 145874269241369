import {
  Box,
  Flex,
  IconButton,
  Stack,
  Collapse,
  useColorModeValue,
  useColorMode,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import ParkingInfoLangContainer from "./ParkingInfo/containers/lang";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import { config } from "../../../config";

const Navbar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  if (isOpen) {
    document.documentElement.style.overflow = "hidden";
  } else {
    document.documentElement.style.overflow = "visible";
  }
  const { colorMode } = useColorMode();

  return (
    <>
      <Box
        className="display-print-none"
        zIndex={5}
        boxShadow="md"
        w="100%"
        sx={{ position: "absolute", top: "0" }}
      >
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          }}
        >
          <Flex
            justify={{
              base: "space-between",
              sm: "space-between",
              md: "space-between",
              lg: "auto",
              xl: "auto",
            }}
            w={{ base: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" }}
            mb={{ base: "0", sm: "0", md: "10px", lg: "auto", xl: "auto" }}
          >
            <Flex
              flex={{ base: 1, sm: 1, md: 1, lg: "auto", xl: "auto" }}
              ml={{ base: -2 }}
              display={{
                base: "flex",
                sm: "flex",
                md: "flex",
                lg: "none",
                xl: "none",
              }}
            >
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>

            <Spacer />

            <Flex display={{ lg: "none" }} mx="30px">
              <a href="https://www.aeropark1000.com/" rel="noopener noreferrer">
                <img
                  src={`/img/logo_${
                    colorMode === "light" ? "dark" : "white"
                  }.png`}
                  alt={config.APP_NAME}
                />
              </a>
            </Flex>

            <Spacer />

            <Flex display={{ lg: "none" }}>
              <ColorModeSwitcher justifySelf="flex-end" />
            </Flex>
          </Flex>

          <Box w="15vw" display={{ base: "none", lg: "block" }}>
            <a href="https://www.aeropark1000.com/" rel="noopener noreferrer">
              <img
                src={`/img/logo_${
                  colorMode === "light" ? "dark" : "white"
                }.png`}
                alt={config.APP_NAME}
              />
            </a>
          </Box>

          <Spacer minW="10px" />

          <Box
            display={{
              base: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            }}
          >
            <ParkingInfoLangContainer />
          </Box>

          <Spacer />

          <Stack
            flex={{ base: 1, lg: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Box display={{ base: "none", lg: "block" }}>
              <ColorModeSwitcher justifySelf="flex-end" />
            </Box>
            <Flex display={{ base: "none", lg: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>

        {isOpen && <Flex bg="white" flex="1" w="100vw" h="100vh"></Flex>}
      </Box>
    </>
  );
};

export default Navbar;
