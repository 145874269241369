import { Flex, Text, Spacer } from "@chakra-ui/layout";
import React from "react";
import { capitalizeFirstLetter } from "../../../../lib/string";

interface ParkingInfoProps {
  messages: any;
}

const ParkingInfo: React.FC<ParkingInfoProps> = ({ messages }) => {
  return (
    <Flex
      direction={{
        base: "column",
        sm: "column",
        md: "column",
        lg: "row",
        xl: "row",
      }}
    >
      <Flex>
        <Flex direction={"column"}>
          <Text fontSize="sm">
            {capitalizeFirstLetter(messages["address"] || "address")}
          </Text>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/gLFGPeDEAMNPBfjv6"
          >
            <Text fontWeight="bold" fontSize="sm">
              Rue de Verdun 679, 1130 Bruxelles
            </Text>
          </a>
        </Flex>
      </Flex>

      <Spacer
        minW="10px"
        w={{ base: "0", xl: "30px" }}
        my={{ base: "10px", xl: "0" }}
      />

      <Flex direction={"column"}>
        <Text fontSize="sm">
          {capitalizeFirstLetter(messages["send_email"] || "send email")}
        </Text>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:aeropark1000@gmail.com"
        >
          <Text fontWeight="bold" fontSize="sm">
            aeropark1000@gmail.com
          </Text>
        </a>
      </Flex>

      <Spacer
        minW="10px"
        w={{ base: "0", xl: "30px" }}
        my={{ base: "10px", xl: "0" }}
      />

      <Flex direction={"column"}>
        <Text fontSize="sm">
          {capitalizeFirstLetter(messages["phone_number"] || "phone number")}
        </Text>
        <a rel="noopener noreferrer" target="_blank" href="tel:+32484280803">
          {" "}
          <Text style={{ direction: "ltr" }} fontWeight="bold" fontSize="sm">
            +32 484 28 08 03
          </Text>
        </a>
      </Flex>
    </Flex>
  );
};

export default ParkingInfo;
