import { Flex } from "@chakra-ui/layout";
import React from "react";

const InnerLoader: React.FC = () => {
  React.useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "visible";
    };
  }, []);
  return (
    <Flex
      zIndex="9999"
      position="fixed"
      transform="translate(0.0001px)"
      w="100vw"
      h="100vh"
      top="0"
      left="0"
      justifyContent="center"
      alignItems="center"
      bg="rgb(255 255 255 / 80%)"
    >
      <Flex>
        <Flex>
          <svg
            version="1.1"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
            width="15vw"
          >
            <circle
              fill="var(--chakra-colors-brand-500)"
              stroke="none"
              cx="6"
              cy="50"
              r="6"
            >
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"
              />
            </circle>
            <circle
              fill="var(--chakra-colors-brand-500)"
              stroke="none"
              cx="26"
              cy="50"
              r="6"
            >
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2"
              />
            </circle>
            <circle
              fill="var(--chakra-colors-brand-500)"
              stroke="none"
              cx="46"
              cy="50"
              r="6"
            >
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3"
              />
            </circle>
          </svg>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InnerLoader;
