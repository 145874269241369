import React from "react";
import useLang from "../../../../hooks/useLang";
import getLangByCode from "../Lang/utils/getLangByCode";

const CurrentLangName = () => {
  const lang = useLang();
  return <span>{getLangByCode(lang)}</span>;
};

export default CurrentLangName;
