import { Flex, Spacer } from "@chakra-ui/layout";
import React from "react";
import useLang from "../../../../../../hooks/useLang";
import useProductReservation from "../../../../../../hooks/useProductReservation";
import { encryptObject } from "../../../../../../utils/crypt";

interface ExtraProductsProps {
  array_IDS: any;
  free: string;
}

const ExtraProducts: React.FC<ExtraProductsProps> = ({ array_IDS, free }) => {
  const lang = useLang();
  const _products: any = useProductReservation(encryptObject({ array_IDS }));

  if (_products !== "" || !lang) {
    const products = _products.products;

    return (
      <>
        {products.map((el: any, idx: number) => (
          <Flex key={idx + "key-x"} py="8px">
            <Flex>{JSON.parse(el.name)[lang]}</Flex>
            <Spacer
              mx="16px"
              style={{ borderBottom: "1.5px dotted rgb(206, 206, 206)" }}
            />
            <Flex>{+el.price === 0 ? free : el.price}</Flex>
          </Flex>
        ))}
      </>
    );
  } else {
    return null;
  }
};

export default ExtraProducts;
