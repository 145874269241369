import React from "react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import DatePicker from "react-date-picker";

import {
  Box,
  Divider,
  Flex,
  HStack,
  Grid,
  GridItem,
  Spacer,
  Text,
} from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import {
  Checkbox,
  Heading,
  Input,
  Select,
  useRadio,
  useRadioGroup,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import { diffDate, getIntFromDate } from "../../lib/date";
import { BiCalendar, BiErrorCircle, BiUser, BiWorld } from "react-icons/bi";
import {
  MdAlternateEmail,
  MdInfoOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineNotificationImportant,
} from "react-icons/md";
import { BsBuilding, BsCheck2Circle, BsPenFill } from "react-icons/bs";
import { FaAddressBook, FaCity } from "react-icons/fa";
import {
  AiOutlineNumber,
  AiOutlineFieldNumber,
  AiOutlineCar,
} from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PRICE_QUERY, RESERVATE } from "../../Apollo/typedefs";
import { decryptObject, encryptObject } from "../../utils/crypt";
import FrontError from "../../Components/FrontError";
import InnerLoader from "../../Components/InnerLoader";
import {
  getAttrOfItemByIdentifier,
  getItemByIdentifier,
  updateObject,
} from "../../lib/object";
import { capitalizeFirstLetter } from "../../lib/string";
import useLang from "../../hooks/useLang";
import { config } from "../../config";
import CountriesSelectContainer from "../../Components/CountiresSelect/container";
import { DragHandleIcon } from "@chakra-ui/icons";
import PhonesSelectContainer from "../../Components/PhonesSelect/container";
import { dialCode } from "../../lib/phone";
import TimeSelectComponent from "../../Components/TimeSelect";

interface reservateRequestType {
  id?: string;
  num_ticket?: string;
  start_date: string;
  start_date_int: number;
  start_time: string;
  end_date: string;
  end_date_int: number;
  end_time: string;
  flight_number_at_start?: string;
  flight_number_at_end?: string;
  travel_for: string;
  company_name?: string | null;
  address?: string | null;
  address_two?: string | null;
  zip_code?: string | null;
  city?: string | null;
  business_country?: string | null;
  vat_number?: string | null;
  full_name: string;
  email: string;
  country: string;
  pure_phone_number: string;
  code_country_phone_number: string;
  full_phone_number: string;
  number_of_people: number;
  make_and_model?: string | null;
  license_plate_number?: string | null;
  code_lang: string;
  id_price: string;
  id_products: string; // array of ids
}

interface Reservation {
  start_date: number;
  start_time: string;
  end_date: number;
  end_time: string;
  days_in_parking: number;
  flight_number_at_start?: string;
  flight_number_at_end?: string;
  travel_for: string;
  company_name?: string;
  address?: string;
  address_two?: string;
  zip_code?: string;
  city?: string;
  business_country?: string;
  vat_number?: string;
  full_name: string;
  country: string;
  pure_phone_number: string;
  code_country_phone_number: string;
  full_phone_number: string;
  email: string;
  confirm_email: string;
  number_of_people: number;
  make_and_model?: string;
  license_plate_number?: string;
  products: string;
  price: number;
  active_products: string;
  products_price: number;
  local_total_price: number;
}

interface ReservationValidation {
  start_date: number;
  start_time: number;
  end_date: number;
  end_time: number;
  days_in_parking: number;
  flight_number_at_start?: number;
  flight_number_at_end?: number;
  company_name?: number;
  address?: number;
  address_two?: number;
  zip_code?: number;
  city?: number;
  business_country?: number;
  vat_number?: number;
  full_name: number;
  country: number;
  pure_phone_number: number;
  email: number;
  confirm_email: number;
  make_and_model?: number;
  license_plate_number?: number;
  check_details: number;
  accept_conditions: number;
}

interface ReservateProps {
  blocked_dates: number[];
  first_enabled_date: number;
  max_enabled_date: number;
  extra_products: any;
  id_price: string;
  messages: any;
  reservation?: any;
}

const correctNames = (txt: string) => {
  switch (txt) {
    case "full_name":
      return "first_and_last_name";
    case "email":
      return "email_address";
    case "confirm_email":
      return "confirm_email_address";
    case "pure_phone_number":
      return "mobile_phone_number";
    case "check_details":
      return "i_checked_my_information_and_the_details_of_the_reservation";
    case "accept_conditions":
      return "i_accept_the_general_conditions_of";
  }
};

const ReservateComponent: React.FC<ReservateProps> = ({
  first_enabled_date,
  blocked_dates,
  max_enabled_date,
  extra_products,
  id_price,
  messages,
  reservation,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  let default_values: any = {};
  if (!reservation) {
    // default_values.date_range = [
    //   moment(first_enabled_date, "YYYYMMDD").locale("en").toDate(),
    //   moment(first_enabled_date, "YYYYMMDD").locale("en").toDate(),
    // ];

    default_values.start_date = moment(first_enabled_date, "YYYYMMDD")
      .locale("en")
      .toDate();
    default_values.end_date = moment(first_enabled_date, "YYYYMMDD")
      .locale("en")
      .toDate();

    default_values.start_time = "10:00";
    default_values.end_time = "10:00";
    default_values.flight_number_at_start = "";
    default_values.flight_number_at_end = "";
    default_values.travel_for = "leisure";
    default_values.company_name = "";
    default_values.address = "";
    default_values.address_two = "";
    default_values.zip_code = "";
    default_values.city = "";
    default_values.business_country = "BE";
    default_values.vat_number = "";
    default_values.full_name = "";
    default_values.country = "BE";
    default_values.pure_phone_number = "";
    default_values.code_country_phone_number = "BE";
    default_values.email = "";
    default_values.confirm_email = "";
    default_values.number_of_people = 4;
    default_values.make_and_model = "";
    default_values.license_plate_number = "";
  } else {
    // default_values.date_range = [
    //   moment(reservation.start_date_int, "YYYYMMDD").locale("en").toDate(),
    //   moment(reservation.end_date_int, "YYYYMMDD").locale("en").toDate(),
    // ];

    default_values.start_date = moment(reservation.start_date_int, "YYYYMMDD")
      .locale("en")
      .toDate();
    default_values.end_date = moment(reservation.end_date_int, "YYYYMMDD")
      .locale("en")
      .toDate();

    default_values.start_time = reservation.start_time;
    default_values.end_time = reservation.end_time;
    default_values.flight_number_at_start =
      reservation.flight_number_at_start || "";
    default_values.flight_number_at_end =
      reservation.flight_number_at_end || "";
    default_values.travel_for = reservation.travel_for;
    default_values.company_name = reservation.company_name || "";
    default_values.address = reservation.address || "";
    default_values.address_two = reservation.address_two || "";
    default_values.zip_code = reservation.zip_code || "";
    default_values.city = reservation.city || "";
    default_values.business_country = reservation.business_country;
    default_values.vat_number = reservation.vat_number || "";
    default_values.full_name = reservation.full_name;
    default_values.country = reservation.country;
    default_values.pure_phone_number = reservation.pure_phone_number;
    default_values.code_country_phone_number =
      reservation.code_country_phone_number;
    default_values.email = reservation.email;
    default_values.confirm_email = reservation.email;
    default_values.number_of_people = reservation.number_of_people;
    default_values.make_and_model = reservation.make_and_model || "";
    default_values.license_plate_number =
      reservation.license_plate_number || "";
  }

  // console.log(" main rendered at ", moment().format(" HH:mm MMMM"));

  const [reservate, { loading: reservateLoading, error: reservateError }] =
    useMutation(RESERVATE);

  const toast = useToast();

  let init_products_state: any = [];

  extra_products.forEach((original_product: any) => {
    init_products_state.push({
      ...original_product,
      state:
        reservation &&
        JSON.parse(reservation && reservation.id_products).includes(
          original_product.id
        )
          ? "yes"
          : "no",
    });
  });

  const [shadow_touched_elements, set_shadow_touched_elements] = React.useState(
    {
      flight_number_at_end: false,
      flight_number_at_start: false,
      company_name: false,
      address: false,
      address_two: false,
      zip_code: false,
      city: false,
      vat_number: false,
      full_name: false,
      pure_phone_number: false,
      email: false,
      confirm_email: false,
      make_and_model: false,
      license_plate_number: false,
      accept_conditions: false,
      check_details: false,
    }
  );

  const colorText = useColorModeValue("white", "gray.200");
  const bgGlobalColor = useColorModeValue("gray.200", "gray.800");
  const bgCardsColor = useColorModeValue("white", "gray.600");

  const colorBGC1 = useColorModeValue("blue.600", "blue.900");
  const colorBGC2 = useColorModeValue("blue.300", "blue.600");

  const [date_range_picker_open, set_date_range_picker_open] =
    React.useState(false);

  // const [date_range, set_date_range] = React.useState(
  //   default_values.date_range
  // );

  const [start_date, set_start_date] = React.useState(
    default_values.start_date
  );

  const [end_date, set_end_date] = React.useState(default_values.end_date);

  const [challenge, set_challenge] = React.useState(
    encryptObject({
      start_date: +moment(start_date).locale("en").format("YYYYMMDD"),
      end_date: +moment(end_date).locale("en").format("YYYYMMDD"),
      id_price: reservation && reservation.id_price,
    })
  );

  const [
    loadPrice,
    {
      loading: get_price_loading,
      error: get_price_error,
      data: data_get_price,
    },
  ] = useLazyQuery(GET_PRICE_QUERY, {
    variables: {
      challenge,
    },
  });

  const [is_submited, set_is_submited] = React.useState(false);

  const [start_time, set_start_time] = React.useState(
    default_values.start_time
  );
  const [end_time, set_end_time] = React.useState(default_values.end_time);

  const [flight_number_at_start, set_flight_number_at_start] = React.useState(
    default_values.flight_number_at_start
  );
  const [flight_number_at_end, set_flight_number_at_end] = React.useState(
    default_values.flight_number_at_end
  );
  const [travel_for, set_travel_for] = React.useState<string>(
    default_values.travel_for
  );
  const [company_name, set_company_name] = React.useState(
    default_values.company_name
  );
  const [address, set_address] = React.useState(default_values.address);
  const [address_two, set_address_two] = React.useState(
    default_values.address_two
  );
  const [zip_code, set_zip_code] = React.useState(default_values.zip_code);
  const [city, set_city] = React.useState(default_values.city);
  const [business_country, set_business_country] = React.useState(
    default_values.business_country
  );
  const [vat_number, set_vat_number] = React.useState(
    default_values.vat_number
  );
  const [full_name, set_full_name] = React.useState(default_values.full_name);
  const [country, set_country] = React.useState(default_values.country);
  const [pure_phone_number, set_pure_phone_number] = React.useState(
    default_values.pure_phone_number
  );
  const [code_country_phone_number, set_code_country_phone_number] =
    React.useState(default_values.code_country_phone_number);
  const [email, set_email] = React.useState(default_values.email);
  const [confirm_email, set_confirm_email] = React.useState(
    default_values.confirm_email
  );
  const [number_of_people, set_number_of_people] = React.useState<number>(
    default_values.number_of_people
  );
  const [make_and_model, set_make_and_model] = React.useState(
    default_values.make_and_model
  );
  const [license_plate_number, set_license_plate_number] = React.useState(
    default_values.license_plate_number
  );
  const [products, set_products] = React.useState(init_products_state);
  const [check_details, set_check_details] = React.useState(false);
  const [accept_conditions, set_accept_conditions] = React.useState(false);
  const [mobile_total_widget, set_mobile_total_widget] = React.useState(false);

  React.useEffect(() => {
    if (
      moment(end_date).locale("en").isBefore(moment(start_date).locale("en"))
    ) {
      set_end_date(start_date);
    }
  }, [start_date, end_date]);

  React.useEffect(() => {
    const inputs: any = window.document.querySelectorAll(
      "#date_range_calendar input"
    );

    if (inputs) {
      for (const input of inputs) {
        input.readOnly = true;
      }
    }

    // console.log(" Hello from useEffect at ", moment().format(" HH:mm "));

    const decrypted = decryptObject(challenge);
    const dec___start_date = decrypted.start_date;
    const dec___end_date = decrypted.end_date;
    const __start_date = +moment(start_date).locale("en").format("YYYYMMDD");
    const __end_date = +moment(end_date).locale("en").format("YYYYMMDD");

    if (
      dec___start_date !== null &&
      dec___end_date !== null &&
      !isNaN(__start_date) &&
      !isNaN(__end_date)
    ) {
      if (dec___start_date !== __start_date || dec___end_date !== __end_date) {
        // console.log("dec___start_date=>", dec___start_date);
        // console.log("__start_date=>", __start_date);
        // console.log("dec___end_date=>", dec___end_date);
        // console.log("__end_date=>", __end_date);

        // console.log(" trigger set_challenge at ", moment().format(" HH:mm "));

        if (reservation && reservation.id_price) {
          set_challenge(
            encryptObject({
              start_date: +moment(start_date).locale("en").format("YYYYMMDD"),
              end_date: +moment(end_date).locale("en").format("YYYYMMDD"),
              id_price: reservation && reservation.id_price,
            })
          );
        } else {
          set_challenge(
            encryptObject({
              start_date: +moment(start_date).locale("en").format("YYYYMMDD"),
              end_date: +moment(end_date).locale("en").format("YYYYMMDD"),
            })
          );
        }

        // console.log("if");

        loadPrice();
      } else {
        // console.log("else");
        loadPrice();
      }
    }
    // eslint-disable-next-line
  }, [start_date, end_date, challenge]);

  const {
    isOpen: isErrorDialogOpen,
    onOpen: onErrorDialogOpen,
    onClose: onErrorDialogClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  // CONSTANTS
  const days_in_parking = +diffDate(start_date, end_date);

  const options = ["leisure", "business"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "travel_for",
    defaultValue: travel_for,
    onChange: set_travel_for,
  });

  const group = getRootProps();

  const is_biss = travel_for === "business" ? true : false;

  let price = 0;
  let id_price_from_resp = id_price;

  if (data_get_price && data_get_price.getPrice) {
    const ressp = decryptObject(data_get_price.getPrice);
    price = ressp.price;
    id_price_from_resp = ressp.id_price;
  }

  let active_products = products.filter((p: any) => p.state === "yes");

  if (number_of_people > 4) {
    active_products.push({
      id: "sup-8-eruo",
      name: messages["8_euro_by_passenger_in_addition"],
      price: `${(number_of_people - 4) * 10}`,
      state: "yes",
    });
  }

  let products_price = 0.0;

  active_products.forEach((el: any) => {
    products_price += +el.price;
  });

  // declare info
  let info: Reservation = {
    start_date: 0,
    start_time: "",
    end_date: 0,
    end_time: "",
    days_in_parking: 0,
    flight_number_at_start: "",
    flight_number_at_end: "",
    travel_for: "",
    company_name: "",
    address: "",
    address_two: "",
    zip_code: "",
    city: "",
    business_country: "",
    vat_number: "",
    full_name: "",
    country: "",
    pure_phone_number: "",
    code_country_phone_number: "",
    full_phone_number: "",
    email: "",
    confirm_email: "",
    number_of_people: 4,
    make_and_model: "",
    license_plate_number: "",
    products: "",
    price: 0,
    active_products: "",
    products_price: 0,
    local_total_price: 0,
  };

  // assign info

  info.start_date = +moment(start_date).locale("en").format("YYYYMMDD");
  info.start_time = start_time;
  info.end_date = +moment(end_date).locale("en").format("YYYYMMDD");
  info.end_time = end_time;
  info.days_in_parking = days_in_parking;
  info.flight_number_at_start = flight_number_at_start;
  info.flight_number_at_end = flight_number_at_end;
  info.travel_for = travel_for;

  if (is_biss) {
    info.company_name = company_name;
    info.address = address;
    info.address_two = address_two;
    info.zip_code = zip_code;
    info.city = city;
    info.business_country = business_country;
    info.vat_number = vat_number;
  }

  info.full_name = full_name;
  info.email = email;
  info.confirm_email = confirm_email;
  info.country = country;
  info.pure_phone_number = pure_phone_number;
  info.code_country_phone_number = code_country_phone_number;
  info.full_phone_number =
    dialCode(code_country_phone_number) + pure_phone_number;
  info.number_of_people = number_of_people;
  info.make_and_model = make_and_model;
  info.license_plate_number = license_plate_number;
  info.products = products;
  info.price = price;
  info.active_products = active_products;
  info.products_price = products_price;
  info.local_total_price = 0 + price + +products_price;

  if (!is_biss) {
    delete info.company_name;
    delete info.address;
    delete info.address_two;
    delete info.zip_code;
    delete info.city;
    delete info.business_country;
    delete info.vat_number;
    delete info.address_two;
  }

  if (flight_number_at_start.length === 0) {
    delete info.flight_number_at_start;
  }

  if (flight_number_at_end.length === 0) {
    delete info.flight_number_at_end;
  }

  // console.log("--------------------- INFO ---------------------");
  // console.table(info);
  // console.log("------------------------------------------------");

  /** 1 => valid , -1 => invalide */
  let validation: ReservationValidation = {
    start_date: 0,
    start_time: 0,
    end_date: 0,
    end_time: 0,
    days_in_parking: 0,
    flight_number_at_start: 0,
    flight_number_at_end: 0,
    company_name: 0,
    address: 0,
    address_two: 0,
    zip_code: 0,
    city: 0,
    business_country: 0,
    vat_number: 0,
    full_name: 0,
    email: 0,
    confirm_email: 0,
    country: 0,
    pure_phone_number: 0,
    make_and_model: 0,
    license_plate_number: 0,
    check_details: 0,
    accept_conditions: 0,
  };

  /** start_date Ex: 20210131 [@type int, length(8)] */
  validation.start_date = isNaN(info.start_date) ? -1 : 1;
  validation.start_date = info.start_date + "".length === 8 ? -1 : 1;

  /** start_date Ex: 16:30 [@type string, length(5)] */
  validation.start_time = 1;

  /** end_date Ex: 20210131 [@type int, length(8)] */
  validation.end_date = isNaN(info.end_date) ? -1 : 1;
  validation.end_date = info.end_date + "".length === 8 ? -1 : 1;

  /** end_time Ex: 16:30 [@type string, length(5)] */
  validation.end_time = 1;

  /** days_in_parking Ex: 1 [@type int, (> 0) ] */
  validation.days_in_parking = isNaN(info.days_in_parking) ? -1 : 1;
  validation.days_in_parking = info.days_in_parking < 1 ? -1 : 1;

  /** flight_number_at_start Ex: XYZ123 [@type string , MAX(16)] OR empty */
  validation.flight_number_at_start =
    (flight_number_at_start.length > 64 || flight_number_at_start.length < 2) &&
    flight_number_at_start.length > 0 &&
    shadow_touched_elements.flight_number_at_start
      ? -1
      : 1;

  /** flight_number_at_end Ex: XYZ123 [@type string , MAX(16)] or empty */
  validation.flight_number_at_end =
    (flight_number_at_end.length > 64 || flight_number_at_end.length < 2) &&
    flight_number_at_end.length > 0 &&
    shadow_touched_elements.flight_number_at_end
      ? -1
      : 1;

  /** company_name Ex: Company [@type string , MIN(3), MAX(32), NOT EMPTY] */

  if (is_biss) {
    validation.company_name = /^.{3,64}$/.test(company_name) && true ? 1 : -1;
  }

  /** address Ex: Company [@type string , MIN(3), MAX(32), NOT EMPTY] */
  if (is_biss) {
    validation.address = /^.{3,64}$/.test(address) && true ? 1 : -1;
  }

  /** address_two Ex: Company [@type string , MIN(3), MAX(32), OR EMPTY] */
  if (is_biss) {
    validation.address_two =
      (address_two.length > 64 || address_two.length < 2) &&
      address_two.length > 0 &&
      shadow_touched_elements.address_two
        ? -1
        : 1;
  }

  /** zip_code Ex: Company [@type string , MIN(3), MAX(16), NOT EMPTY] */
  if (is_biss) {
    validation.zip_code = zip_code.length > 16 || zip_code.length < 2 ? -1 : 1;
  }

  /** city Ex: Company [@type string , MIN(3), MAX(32), NOT EMPTY] */
  if (is_biss) {
    validation.city = city.length > 64 || city.length < 2 ? -1 : 1;
  }

  /** vat_number Ex: Company [@type string , MIN(3), MAX(64), NOT EMPTY] */
  if (is_biss) {
    validation.vat_number =
      vat_number.length > 64 || vat_number.length < 2 ? -1 : 1;
  }

  /** tmp_validation_full_name Ex: Company [@type string , MIN(3), MAX(32), NOT EMPTY] */
  let tmp_validation_full_name = [-1, -2];

  full_name.length >= 32 || full_name.length < 2
    ? (tmp_validation_full_name[0] = -1)
    : (tmp_validation_full_name[0] = 1);

  // check if there are space between two words (first and last)
  !full_name.includes(" ")
    ? (tmp_validation_full_name[1] = -2)
    : (tmp_validation_full_name[1] = 1);

  validation.full_name =
    tmp_validation_full_name[0] === 1 && tmp_validation_full_name[1] === 1
      ? 1
      : -1;

  /** pure_phone_number Ex: Company [@type Int , MIN(6), MAX(16), NOT EMPTY] */

  validation.pure_phone_number =
    info.pure_phone_number.length > 64 || info.pure_phone_number.length < 6
      ? -1
      : 1;

  /** email Ex: email [@type string , email pattern regex, NOT EMPTY] */
  validation.email =
    /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(String(email).toLowerCase()) &&
    email.length > 0 &&
    shadow_touched_elements.email
      ? 1
      : -1;

  /** confirm_email Ex: email [@type string , email pattern regex, NOT EMPTY] */
  validation.confirm_email =
    /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(
      String(confirm_email).toLowerCase()
    ) &&
    confirm_email.length > 0 &&
    shadow_touched_elements.confirm_email
      ? 1
      : -1;

  /** make_and_model Ex: make_and_model [@type string , MIN 6 MAX 32, NOT EMPTY] */
  validation.make_and_model =
    (make_and_model.length > 64 || make_and_model.length < 2) &&
    make_and_model.length > 0 &&
    shadow_touched_elements.make_and_model
      ? -1
      : 1;

  /** license_plate_number Ex: license_plate_number [@type string , MIN 6 MAX 32, NOT EMPTY] */
  validation.license_plate_number =
    (license_plate_number.length > 64 || license_plate_number.length < 2) &&
    license_plate_number.length > 0 &&
    shadow_touched_elements.license_plate_number
      ? -1
      : 1;

  validation.check_details = check_details ? 1 : -1;
  validation.accept_conditions = accept_conditions ? 1 : -1;

  if (!is_biss) {
    delete validation.company_name;
    delete validation.address;
    delete validation.address_two;
    delete validation.zip_code;
    delete validation.city;
    delete validation.business_country;
    delete validation.vat_number;
  }

  const invalid_elements: any[] = Object.keys(validation).filter((el: any) => {
    let attr: keyof ReservationValidation = el;
    return validation[attr] === -1;
  });

  // console.log("invalid_elements=>", invalid_elements);

  // console.log("--------------------- VALIDATION ---------------------");
  // console.table(validation);
  // console.log("------------------------------------------------");
  const onSubmitHandler = async () => {
    // console.log("onSubmitHandler");

    // set all inputs as touched

    set_shadow_touched_elements({
      flight_number_at_end: true,
      flight_number_at_start: true,
      company_name: true,
      address: true,
      address_two: true,
      zip_code: true,
      city: true,
      vat_number: true,
      full_name: true,
      pure_phone_number: true,
      email: true,
      confirm_email: true,
      make_and_model: true,
      license_plate_number: true,
      accept_conditions: true,
      check_details: true,
    });

    if (invalid_elements.length > 0) {
      // console.log(invalid_elements);

      onErrorDialogOpen();
    } else {
      // reservate

      if (!reservateLoading) {
        const reservateRequest: reservateRequestType = {
          id: reservation && reservation.id,
          num_ticket: reservation && reservation.num_ticket,
          start_date: moment.utc(start_date).toString(),
          start_date_int: +moment(start_date).locale("en").format("YYYYMMDD"),
          start_time,
          end_date: moment.utc(end_date).toString(),
          end_date_int: +moment(end_date).locale("en").format("YYYYMMDD"),
          end_time,
          flight_number_at_start,
          flight_number_at_end,
          travel_for,
          company_name: is_biss ? company_name : null,
          address: is_biss ? address : null,
          address_two: is_biss ? address_two : null,
          zip_code: is_biss ? zip_code : null,
          city: is_biss ? city : null,
          business_country: is_biss ? business_country : null,
          vat_number: is_biss ? vat_number : null,
          full_name,
          email,
          country,
          pure_phone_number,
          code_country_phone_number,
          full_phone_number:
            dialCode(code_country_phone_number) + pure_phone_number,
          number_of_people,
          make_and_model: make_and_model ? make_and_model : null,
          license_plate_number: license_plate_number
            ? license_plate_number
            : null,
          code_lang: lang,
          id_price: id_price_from_resp,
          id_products: JSON.stringify(active_products.map((el: any) => el.id)),
        };

        const { data } = await reservate({
          variables: {
            challenge: encryptObject({ ...reservateRequest }),
          },
        });

        if (!data) {
          // console.log("something went wrong 01");
        }
        // console.log(data);

        const { msg } = decryptObject(data.reservate);
        if (!msg) {
          // console.log("something went wrong 02");
        }

        await set_is_submited(true);

        if (msg === "created") {
          toast({
            title: capitalizeFirstLetter(
              messages["your_reservation_has_been_accepted"]
            ),
            description: capitalizeFirstLetter(
              messages["weve_accepte_your_reservation"]
            ),
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: capitalizeFirstLetter(messages["something_was_wrong"]),
            description: `${msg}`,
            status: "error",
            isClosable: true,
          });
        }
        setTimeout(() => {
          window.location.href = config.APP_HOME_URL;
        }, 2500);
      }
    }
  };

  // const isSameInSameDate = start_date.toString() === end_date.toString();

  // const getStartTimeIndex = hours.indexOf(start_time);

  // console.log(hours);
  // const hoursEnd = hours.slice(getStartTimeIndex + 1, hours.length);
  // console.log(hoursEnd);

  return (
    <>
      {(get_price_loading || reservateLoading) && !reservateError && (
        <InnerLoader />
      )}
      {((get_price_error && get_price_error.message) ||
        (reservateError && reservateError.message)) && <FrontError />}
      <Box>
        <Box
          display={
            !get_price_loading &&
            !reservateLoading &&
            ((get_price_error && get_price_error.message) ||
              (reservateError && reservateError.message))
              ? "none"
              : ""
          }
        >
          <Box
            px="2vw"
            py="7.5vh"
            bgGradient={`linear(to-r, ${colorBGC1}, ${colorBGC2})`}
            color={colorText}
            fontSize="5xl"
            fontWeight="bold"
          >
            {capitalizeFirstLetter(
              reservation && reservation.id
                ? messages["edit_booking"]
                : messages["booking"]
            )}
            <Heading size="sm" color="gray.200">
              {capitalizeFirstLetter(
                messages["please_enter_your_information"] ||
                  "please enter your information"
              )}
            </Heading>
          </Box>
          <Box px="3" pb="100" pt="5" bg={bgGlobalColor}>
            {/* {{ sm: "red", md: "blue", lg: "purple", xl: "brown" }} */}

            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
                lg: "repeat(1, 1fr)",
                xl: "repeat(1, 1fr)",
              }}
              gap={8}
              mx={{
                base: "1vw",
                md: "10vw",
                lg: "20vw",
                xl: "25vw",
              }}
            >
              <GridItem
                colSpan={{
                  base: 3,
                  md: 3,
                  lg: 2,
                  xl: 3,
                }}
              >
                <Heading mt="32px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(
                    messages["your_travel_details"] || "your travel details"
                  )}
                </Heading>
                <Box>
                  <Flex
                    direction={{ base: "column", sm: "column", lg: "row" }}
                    mb="8"
                    mt="3"
                    boxShadow="lg"
                    bg={bgCardsColor}
                    rounded="6"
                    p="3"
                  >
                    <Box
                      pl={
                        document.body.style.direction === "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pr={
                        document.body.style.direction !== "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pb={{ base: "6", sm: "6", lg: "0" }}
                      flex="1"
                      fontSize="md"
                    >
                      <Flex>
                        <Heading size="xs" mb="2">
                          {capitalizeFirstLetter(
                            messages["start_date"] || "start date"
                          )}{" "}
                          *
                        </Heading>
                        <Spacer />
                        {validation.start_date === -1 ||
                        validation.start_time === -1 ? (
                          <Icon
                            as={BiErrorCircle}
                            fontSize={16}
                            color="red.500"
                          />
                        ) : (
                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        )}
                      </Flex>

                      <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        direction={"column"}
                      >
                        <Flex
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Icon
                          mr="5px"
                          as={BiCalendar}
                          fontSize={16}
                          color="brand.500"
                        />
                        <DatePicker
                          locale={lang}
                          className="css-45ghr4e65h"
                          clearIcon={null}
                          minDate={moment(first_enabled_date, "YYYYMMDD")
                            .locale("en")
                            .toDate()}
                          prev2Label={null}
                          next2Label={null}
                          onChange={set_start_date}
                          value={start_date}
                          readOnly={true}
                          inputProps={{ readOnly: true }}
                          onFocus={(e) => e.target.blur()}
                          isOpen={date_range_picker_open}
                          onCalendarClose={() => {
                            set_date_range_picker_open(false);
                          }}
                          calendarIcon={null}
                          tileDisabled={(p: any) => {
                            const date = getIntFromDate(p.date);

                            if (blocked_dates.includes(date)) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                        />
                      </Flex>

                        <TimeSelectComponent
                          reff="start_time"
                          defaultValue={start_time}
                          onChange={set_start_time}
                        />
                      </Flex>
                    </Box>

                    <Box
                      pl={
                        document.body.style.direction === "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pr={
                        document.body.style.direction !== "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pb={{ base: "6", sm: "6", lg: "0" }}
                      flex="1"
                      fontSize="md"
                    >
                      <Flex>
                        <Heading size="xs" mb="2">
                          {capitalizeFirstLetter(
                            messages["end_date"] || "end date"
                          )}{" "}
                          *
                        </Heading>
                        <Spacer />
                        {validation.end_date === -1 ||
                        validation.end_time === -1 ? (
                          <Icon
                            as={BiErrorCircle}
                            fontSize={16}
                            color="red.500"
                          />
                        ) : (
                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        )}
                      </Flex>

                      <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        direction={"column"}
                      >
                        <Flex justifyContent="center" alignItems="center">
                          <Icon
                            mr="5px"
                            as={BiCalendar}
                            fontSize={16}
                            color="brand.500"
                          />
                          <DatePicker
                            locale={lang}
                            className="css-45ghr4e65h"
                            clearIcon={null}
                            minDate={moment(first_enabled_date, "YYYYMMDD")
                              .locale("en")
                              .toDate()}
                            prev2Label={null}
                            next2Label={null}
                            readOnly={true}
                            inputProps={{ readOnly: true }}
                            onFocus={(e) => e.target.blur()}
                            onChange={set_end_date}
                            value={end_date}
                            isOpen={date_range_picker_open}
                            onCalendarClose={() => {
                              set_date_range_picker_open(false);
                            }}
                            calendarIcon={null}
                            tileDisabled={(p: any) => {
                              const date = getIntFromDate(p.date);

                              if (
                                blocked_dates.includes(date) ||
                                moment(p.date)
                                  .locale("en")
                                  .isBefore(moment(start_date).locale("en"))
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                          />
                        </Flex>

                        <TimeSelectComponent
                          reff="end_time"
                          defaultValue={end_time}
                          onChange={set_end_time}
                        />
                      </Flex>
                    </Box>
                  </Flex>

                  <Flex
                    direction={{ base: "column", sm: "column", lg: "row" }}
                    mb="8"
                    mt="3"
                    boxShadow="lg"
                    bg={bgCardsColor}
                    rounded="6"
                    p="3"
                  >
                    <Box
                      pl={
                        document.body.style.direction === "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pr={
                        document.body.style.direction !== "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pb={{ base: "6", sm: "6", lg: "0" }}
                      flex="1"
                      fontSize="md"
                    >
                      <Flex>
                        <Heading size="xs" mb="2">
                          {capitalizeFirstLetter(
                            messages["flight_number_at_start"] ||
                              "flight number at start"
                          )}
                        </Heading>
                        <Spacer />
                        {validation.flight_number_at_start === -1 ? (
                          <Icon
                            as={BiErrorCircle}
                            fontSize={16}
                            color="red.500"
                          />
                        ) : (
                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        )}
                      </Flex>

                      <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                      <Box>
                        <InputGroup>
                          <InputLeftElement
                            right={
                              document.body.style.direction === "rtl"
                                ? "0"
                                : "unset"
                            }
                            size="sm"
                            pb="2"
                            pointerEvents="none"
                            children={
                              <DragHandleIcon
                                w={3.5}
                                h={3.5}
                                color="gray.300"
                              />
                            }
                          />
                          <Input
                            onBlur={() => {
                              let tmp = { ...shadow_touched_elements };
                              tmp.flight_number_at_start = true;
                              set_shadow_touched_elements(tmp);
                              set_flight_number_at_start(
                                flight_number_at_start
                                  .replace(/\s+/g, " ")
                                  .replace(/'|"/g, "’")
                                  .trim()
                                  .toUpperCase()
                              );
                            }}
                            value={flight_number_at_start}
                            onChange={(e) =>
                              set_flight_number_at_start(
                                e.target.value.toUpperCase()
                              )
                            }
                            placeholder="XYZ123"
                            size="sm"
                          />
                        </InputGroup>
                      </Box>
                      {validation.flight_number_at_start === -1 && (
                        <Box fontSize="md">
                          <Flex mt="3">
                            <Icon
                              as={MdOutlineNotificationImportant}
                              w={3.5}
                              h={3.5}
                              color="red.300"
                            />
                            <Text
                              fontSize="sm"
                              fontStyle="italic"
                              fontWeight="light"
                              color="red.600"
                              pl="3"
                            >
                              {capitalizeFirstLetter(
                                messages[
                                  "the_length_must_be_between_3_and_64_characters_or_empty"
                                ] ||
                                  "the length must be between 3 and 64 characters or empty."
                              )}
                            </Text>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                    <Box flex="1" fontSize="md">
                      <Flex>
                        <Heading size="xs" mb="2">
                          {capitalizeFirstLetter(
                            messages["flight_number_at_end"] ||
                              "flight number at end"
                          )}
                        </Heading>
                        <Spacer />
                        {validation.flight_number_at_end === -1 ? (
                          <Icon
                            as={BiErrorCircle}
                            fontSize={16}
                            color="red.500"
                          />
                        ) : (
                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        )}
                      </Flex>
                      <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                      <Box>
                        <InputGroup>
                          <InputLeftElement
                            right={
                              document.body.style.direction === "rtl"
                                ? "0"
                                : "unset"
                            }
                            size="sm"
                            pb="2"
                            pointerEvents="none"
                            children={
                              <DragHandleIcon
                                w={3.5}
                                h={3.5}
                                color="gray.300"
                              />
                            }
                          />
                          <Input
                            onBlur={() => {
                              let tmp = { ...shadow_touched_elements };
                              tmp.flight_number_at_end = true;
                              set_shadow_touched_elements(tmp);
                              set_flight_number_at_end(
                                flight_number_at_end
                                  .replace(/\s+/g, " ")
                                  .replace(/'|"/g, "’")
                                  .trim()
                                  .toUpperCase()
                              );
                            }}
                            value={flight_number_at_end}
                            onChange={(e) =>
                              set_flight_number_at_end(
                                e.target.value.toUpperCase()
                              )
                            }
                            placeholder="ZYX456"
                            size="sm"
                          />
                        </InputGroup>
                      </Box>
                      {validation.flight_number_at_end === -1 && (
                        <Box fontSize="md">
                          <Flex mt="3">
                            <Icon
                              as={MdOutlineNotificationImportant}
                              w={3.5}
                              h={3.5}
                              color="red.300"
                            />
                            <Text
                              fontSize="sm"
                              fontStyle="italic"
                              fontWeight="light"
                              color="red.600"
                              pl="3"
                            >
                              {capitalizeFirstLetter(
                                messages[
                                  "the_length_must_be_between_3_and_64_characters_or_empty"
                                ] ||
                                  "the length must be between 3 and 64 characters or empty."
                              )}
                            </Text>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  </Flex>

                  <Box
                    mt="3"
                    bg={bgCardsColor}
                    boxShadow="lg"
                    rounded="6"
                    p="3"
                  >
                    <Flex>
                      <Heading size="xs" mb="2">
                        {capitalizeFirstLetter(
                          messages["travel_for"] || "travel for"
                        )}{" "}
                        *
                      </Heading>
                      <Spacer />
                      <Icon
                        as={BsCheck2Circle}
                        fontSize={16}
                        color="green.500"
                      />
                    </Flex>
                    <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                    <HStack {...group}>
                      {options.map((value: string) => {
                        const radio = getRadioProps({
                          value,
                        });
                        return (
                          <RadioCard key={value} {...radio}>
                            {messages[value]}
                          </RadioCard>
                        );
                      })}
                    </HStack>
                  </Box>
                </Box>
                {is_biss && (
                  <Box>
                    <Heading mt="48px" fontSize="2xl">
                      {capitalizeFirstLetter(
                        messages["company_information"] || "company information"
                      )}
                    </Heading>
                    <Divider />
                    <Flex
                      direction={{ base: "column", sm: "column", lg: "row" }}
                      mb="8"
                      mt="3"
                      boxShadow="lg"
                      bg={bgCardsColor}
                      rounded="6"
                      p="3"
                    >
                      <Box
                        pl={
                          document.body.style.direction === "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pr={
                          document.body.style.direction !== "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pb={{ base: "6", sm: "6", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["company_name"] || "company name"
                            )}{" "}
                            *
                          </Heading>
                          <Spacer />

                          {shadow_touched_elements.company_name &&
                          validation.company_name === -1 ? (
                            <Icon
                              as={BiErrorCircle}
                              fontSize={16}
                              color="red.500"
                            />
                          ) : !shadow_touched_elements.company_name ? (
                            <Icon
                              as={BsPenFill}
                              fontSize={16}
                              color="blue.500"
                            />
                          ) : (
                            <Icon
                              as={BsCheck2Circle}
                              fontSize={16}
                              color="green.500"
                            />
                          )}
                        </Flex>

                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                        <Box>
                          <InputGroup>
                            <InputLeftElement
                              right={
                                document.body.style.direction === "rtl"
                                  ? "0"
                                  : "unset"
                              }
                              size="sm"
                              pb="2"
                              pointerEvents="none"
                              children={
                                <Icon
                                  as={BsBuilding}
                                  w={3.5}
                                  h={3.5}
                                  color="gray.300"
                                />
                              }
                            />
                            <Input
                              onBlur={() => {
                                let tmp = { ...shadow_touched_elements };
                                tmp.company_name = true;
                                set_shadow_touched_elements(tmp);
                                set_company_name(
                                  company_name
                                    .replace(/\s+/g, " ")
                                    .replace(/'|"/g, "’")
                                    .trim()
                                );
                              }}
                              value={company_name}
                              onChange={(e) => set_company_name(e.target.value)}
                              placeholder="Aeropark100"
                              size="sm"
                            />
                          </InputGroup>
                        </Box>
                        {shadow_touched_elements.company_name &&
                          validation.company_name === -1 && (
                            <Box fontSize="md">
                              <Flex mt="3">
                                <Icon
                                  as={MdOutlineNotificationImportant}
                                  w={3.5}
                                  h={3.5}
                                  color="red.300"
                                />
                                <Text
                                  fontSize="sm"
                                  fontStyle="italic"
                                  fontWeight="light"
                                  color="red.600"
                                  pl="3"
                                >
                                  {capitalizeFirstLetter(
                                    messages[
                                      "the_length_must_be_between_3_and_32_characters"
                                    ] ||
                                      "the length must be between 3 and 32 characters."
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          )}
                      </Box>
                      <Box flex="1" fontSize="md">
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["address"] || "address"
                            )}{" "}
                            *
                          </Heading>
                          <Spacer />

                          {shadow_touched_elements.address &&
                          validation.address === -1 ? (
                            <Icon
                              as={BiErrorCircle}
                              fontSize={16}
                              color="red.500"
                            />
                          ) : !shadow_touched_elements.address ? (
                            <Icon
                              as={BsPenFill}
                              fontSize={16}
                              color="blue.500"
                            />
                          ) : (
                            <Icon
                              as={BsCheck2Circle}
                              fontSize={16}
                              color="green.500"
                            />
                          )}
                        </Flex>
                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                        <Box>
                          <InputGroup>
                            <InputLeftElement
                              right={
                                document.body.style.direction === "rtl"
                                  ? "0"
                                  : "unset"
                              }
                              size="sm"
                              pb="2"
                              pointerEvents="none"
                              children={
                                <Icon
                                  as={FaAddressBook}
                                  w={3.5}
                                  h={3.5}
                                  color="gray.300"
                                />
                              }
                            />
                            <Input
                              onBlur={() => {
                                let tmp = { ...shadow_touched_elements };
                                tmp.address = true;
                                set_shadow_touched_elements(tmp);
                                set_address(
                                  address
                                    .replace(/\s+/g, " ")
                                    .replace(/'|"/g, "’")
                                    .trim()
                                );
                              }}
                              value={address}
                              onChange={(e) => set_address(e.target.value)}
                              placeholder="Rue de Verdun 679, 1130 Bruxelles"
                              size="sm"
                            />
                          </InputGroup>
                        </Box>
                        {shadow_touched_elements.address &&
                          validation.address === -1 && (
                            <Box fontSize="md">
                              <Flex mt="3">
                                <Icon
                                  as={MdOutlineNotificationImportant}
                                  w={3.5}
                                  h={3.5}
                                  color="red.300"
                                />
                                <Text
                                  fontSize="sm"
                                  fontStyle="italic"
                                  fontWeight="light"
                                  color="red.600"
                                  pl="3"
                                >
                                  {capitalizeFirstLetter(
                                    messages[
                                      "the_length_must_be_between_3_and_32_characters"
                                    ] ||
                                      "the length must be between 3 and 32 characters."
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          )}
                      </Box>
                    </Flex>
                    <Flex
                      direction={{ base: "column", sm: "column", lg: "row" }}
                      mb="8"
                      mt="3"
                      boxShadow="lg"
                      bg={bgCardsColor}
                      rounded="6"
                      p="3"
                    >
                      <Box
                        pl={
                          document.body.style.direction === "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pr={
                          document.body.style.direction !== "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pb={{ base: "6", sm: "6", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["address2"] || "address 2"
                              )}
                            </Heading>
                            <Spacer />
                            {validation.address_two === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={FaAddressBook}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.address_two = true;
                                  set_shadow_touched_elements(tmp);
                                  set_address_two(
                                    address_two
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                value={address_two}
                                onChange={(e) =>
                                  set_address_two(e.target.value)
                                }
                                placeholder="Rue de Verdun 679, 1130 Bruxelles II"
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                        </Box>
                        {validation.address_two === -1 && (
                          <Box fontSize="md">
                            <Flex mt="3">
                              <Icon
                                as={MdOutlineNotificationImportant}
                                w={3.5}
                                h={3.5}
                                color="red.300"
                              />
                              <Text
                                fontSize="sm"
                                fontStyle="italic"
                                fontWeight="light"
                                color="red.600"
                                pl="3"
                              >
                                {capitalizeFirstLetter(
                                  messages[
                                    "the_length_must_be_between_3_and_32_characters_or_empty"
                                  ] ||
                                    "the length must be between 3 and 32 characters or empty."
                                )}
                              </Text>
                            </Flex>
                          </Box>
                        )}
                      </Box>
                      <Box flex="1" fontSize="md">
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["zip_code"] || "ZIP code"
                              )}{" "}
                              *
                            </Heading>
                            <Spacer />

                            {shadow_touched_elements.zip_code &&
                            validation.zip_code === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : !shadow_touched_elements.zip_code ? (
                              <Icon
                                as={BsPenFill}
                                fontSize={16}
                                color="blue.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>
                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={AiOutlineNumber}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.zip_code = true;
                                  set_shadow_touched_elements(tmp);
                                  set_zip_code(
                                    zip_code
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                type="number"
                                value={zip_code}
                                onChange={(e) => set_zip_code(e.target.value)}
                                placeholder={
                                  capitalizeFirstLetter(
                                    messages["zip_code"] || "ZIP code"
                                  ) + "..."
                                }
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          {shadow_touched_elements.zip_code &&
                            validation.zip_code === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "the_length_must_be_between_3_and_64_digits_or_empty"
                                      ] ||
                                        "the length must be between 3 and 64 digits or empty."
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Flex>
                    <Flex
                      direction={{ base: "column", sm: "column", lg: "row" }}
                      mb="8"
                      mt="3"
                      boxShadow="lg"
                      bg={bgCardsColor}
                      rounded="6"
                      p="3"
                    >
                      <Box
                        pl={
                          document.body.style.direction === "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pr={
                          document.body.style.direction !== "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pb={{ base: "6", sm: "6", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["city"] || "city"
                              )}{" "}
                              *
                            </Heading>

                            <Spacer />

                            {shadow_touched_elements.city &&
                            validation.city === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : !shadow_touched_elements.city ? (
                              <Icon
                                as={BsPenFill}
                                fontSize={16}
                                color="blue.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>
                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={FaCity}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />

                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.city = true;
                                  set_shadow_touched_elements(tmp);
                                  set_city(
                                    city
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                value={city}
                                onChange={(e) => set_city(e.target.value)}
                                placeholder={
                                  capitalizeFirstLetter(
                                    messages["city"] || "city"
                                  ) + "..."
                                }
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          {shadow_touched_elements.city &&
                            validation.city === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "the_length_must_be_between_3_and_64_characters"
                                      ] ||
                                        "the length must be between 3 and 64 characters."
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                        </Box>
                      </Box>{" "}
                      <Box flex="1" fontSize="md">
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["country"] || "country"
                              )}
                            </Heading>
                            <Spacer />

                            <Icon
                              as={BsCheck2Circle}
                              fontSize={16}
                              color="green.500"
                            />
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />

                          <Box style={{ direction: "ltr" }}>
                            <InputGroup>
                              <Flex
                                px="2"
                                pt="1.5"
                                pb=".5"
                                borderWidth="1px"
                                borderRight="0"
                                borderColor="gray.200"
                              >
                                <Icon
                                  as={BiWorld}
                                  w={3.5}
                                  h={3.5}
                                  color="gray.300"
                                />
                              </Flex>
                              <Flex flex="1">
                                <CountriesSelectContainer
                                  defaultValueCode={business_country}
                                  set_={set_business_country}
                                />
                              </Flex>
                            </InputGroup>
                          </Box>
                        </Box>
                      </Box>
                    </Flex>
                    <Flex
                      direction={{ base: "column", sm: "column", lg: "row" }}
                      mb="8"
                    >
                      <Box
                        flex="1"
                        mt="3"
                        bg={bgCardsColor}
                        boxShadow="lg"
                        rounded="6"
                        p="3"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["vat_number"] || "VAT number"
                              )}{" "}
                              *
                            </Heading>

                            <Spacer />

                            {shadow_touched_elements.vat_number &&
                            validation.vat_number === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : !shadow_touched_elements.vat_number ? (
                              <Icon
                                as={BsPenFill}
                                fontSize={16}
                                color="blue.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={AiOutlineFieldNumber}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />

                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.vat_number = true;
                                  set_shadow_touched_elements(tmp);
                                  set_vat_number(
                                    vat_number
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                value={vat_number}
                                onChange={(e) => set_vat_number(e.target.value)}
                                placeholder={
                                  capitalizeFirstLetter(
                                    messages["vat_number"] || "VAT number"
                                  ) + "..."
                                }
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          {shadow_touched_elements.vat_number &&
                            validation.vat_number === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "the_length_must_be_between_3_and_64_characters"
                                      ] ||
                                        "the length must be between 3 and 64 characters."
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                )}

                <Heading mt="48px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(
                    messages["your_information"] || "your information"
                  )}
                </Heading>
                <Box>
                  <Flex
                    direction={{ base: "column", sm: "column", lg: "row" }}
                    mb="8"
                    bg={bgCardsColor}
                    boxShadow="lg"
                    rounded="6"
                    p="3"
                  >
                    <Box
                      flex="1"
                      fontSize="md"
                      pl={
                        document.body.style.direction === "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pr={
                        document.body.style.direction !== "rtl"
                          ? { base: "0", sm: "0", lg: "3" }
                          : { base: "0", sm: "0", lg: "0" }
                      }
                      pb={{ base: "6", sm: "6", lg: "0" }}
                    >
                      <Box fontSize="md">
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["first_and_last_name"] ||
                                "first and last name"
                            )}{" "}
                            *
                          </Heading>
                          <Spacer />

                          {shadow_touched_elements.full_name &&
                          (validation.full_name === -1 ||
                            validation.full_name === -2) ? (
                            <Icon
                              as={BiErrorCircle}
                              fontSize={16}
                              color="red.500"
                            />
                          ) : !shadow_touched_elements.full_name ? (
                            <Icon
                              as={BsPenFill}
                              fontSize={16}
                              color="blue.500"
                            />
                          ) : (
                            <Icon
                              as={BsCheck2Circle}
                              fontSize={16}
                              color="green.500"
                            />
                          )}
                        </Flex>

                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                        <Box>
                          <InputGroup>
                            <InputLeftElement
                              right={
                                document.body.style.direction === "rtl"
                                  ? "0"
                                  : "unset"
                              }
                              size="sm"
                              pb="2"
                              pointerEvents="none"
                              children={
                                <Icon
                                  as={BiUser}
                                  w={3.5}
                                  h={3.5}
                                  color="gray.300"
                                />
                              }
                            />
                            <Input
                              onBlur={() => {
                                let tmp = { ...shadow_touched_elements };
                                tmp.full_name = true;
                                set_shadow_touched_elements(tmp);
                                set_full_name(
                                  full_name
                                    .replace(/\s+/g, " ")
                                    .replace(/'|"/g, "’")
                                    .trim()
                                );
                              }}
                              value={full_name}
                              onChange={(e) => set_full_name(e.target.value)}
                              placeholder="John Doe"
                              size="sm"
                            />
                          </InputGroup>
                        </Box>
                        {shadow_touched_elements.full_name &&
                          tmp_validation_full_name[0] === -1 && (
                            <Box fontSize="md">
                              <Flex mt="3">
                                <Icon
                                  as={MdOutlineNotificationImportant}
                                  w={3.5}
                                  h={3.5}
                                  color="red.300"
                                />
                                <Text
                                  fontSize="sm"
                                  fontStyle="italic"
                                  fontWeight="light"
                                  color="red.600"
                                  pl="3"
                                >
                                  {capitalizeFirstLetter(
                                    messages[
                                      "the_length_must_be_between_3_and_32_characters"
                                    ] ||
                                      "the length must be between 3 and 32 characters."
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          )}

                        {shadow_touched_elements.full_name &&
                          tmp_validation_full_name[1] === -2 && (
                            <Box fontSize="md">
                              <Flex mt="3">
                                <Icon
                                  as={MdOutlineNotificationImportant}
                                  w={3.5}
                                  h={3.5}
                                  color="red.300"
                                />
                                <Text
                                  fontSize="sm"
                                  fontStyle="italic"
                                  fontWeight="light"
                                  color="red.600"
                                  pl="3"
                                >
                                  {capitalizeFirstLetter(
                                    messages[
                                      "please_enter_your_first_name_and_your_last_name"
                                    ] ||
                                      "please enter your first name and your last name."
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    <Box
                      pb={{ base: "6", sm: "3", lg: "0" }}
                      flex="1"
                      fontSize="md"
                    >
                      <Box fontSize="md">
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["country"] || "country"
                            )}
                          </Heading>
                          <Spacer />

                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        </Flex>

                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />

                        <Box style={{ direction: "ltr" }}>
                          <InputGroup>
                            <Flex
                              px="2"
                              pt="1.5"
                              pb=".5"
                              borderWidth="1px"
                              borderRight="0"
                              borderColor="gray.200"
                            >
                              <Icon
                                as={BiWorld}
                                w={3.5}
                                h={3.5}
                                color="gray.300"
                              />
                            </Flex>
                            <Flex flex="1">
                              <CountriesSelectContainer
                                defaultValueCode={country}
                                set_={set_country}
                              />
                            </Flex>
                          </InputGroup>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>

                  <Flex
                    direction="column"
                    mb="8"
                    mt="3"
                    boxShadow="lg"
                    bg={bgCardsColor}
                    rounded="6"
                    p="3"
                  >
                    <Flex direction={{ base: "column", "2xl": "row" }}>
                      <Box
                        pl={
                          document.body.style.direction === "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pr={
                          document.body.style.direction !== "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pb={{ base: "6", sm: "6", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["email_address"] || "email address"
                              )}{" "}
                              *
                            </Heading>
                            <Spacer />

                            {shadow_touched_elements.email &&
                            (validation.email === -1 ||
                              validation.email === -2) ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : !shadow_touched_elements.email ? (
                              <Icon
                                as={BsPenFill}
                                fontSize={16}
                                color="blue.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={MdAlternateEmail}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.email = true;
                                  set_shadow_touched_elements(tmp);
                                  set_email(
                                    email
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                      .toLowerCase()
                                  );
                                }}
                                type="email"
                                value={email}
                                onChange={(e) =>
                                  set_email(e.target.value.toLowerCase())
                                }
                                placeholder="jhon.doe@exemple.com"
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          {shadow_touched_elements.email &&
                            validation.email === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "please_provide_a_valid_email_address"
                                      ] ||
                                        "please provide a valid email address"
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                        </Box>
                      </Box>
                      <Box
                        pb={{ base: "6", sm: "3", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["confirm_email_address"] ||
                                  "confirm email address"
                              )}{" "}
                              *
                            </Heading>
                            <Spacer />

                            {shadow_touched_elements.confirm_email &&
                            (validation.confirm_email === -1 ||
                              validation.confirm_email === -2) ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : !shadow_touched_elements.confirm_email ? (
                              <Icon
                                as={BsPenFill}
                                fontSize={16}
                                color="blue.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={MdAlternateEmail}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.confirm_email = true;
                                  set_shadow_touched_elements(tmp);
                                  set_confirm_email(
                                    confirm_email
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                      .toLowerCase()
                                  );
                                }}
                                type="email"
                                value={confirm_email}
                                onChange={(e) =>
                                  set_confirm_email(
                                    e.target.value.toLowerCase()
                                  )
                                }
                                placeholder="jhon.doe@exemple.com"
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          {shadow_touched_elements.confirm_email &&
                            validation.confirm_email === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "please_provide_a_valid_confirm_email_address"
                                      ] ||
                                        "please provide a valid confirm email address"
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Flex>
                    <Flex>
                      {email !== confirm_email &&
                        shadow_touched_elements.confirm_email &&
                        validation.confirm_email === 1 &&
                        validation.email === 1 &&
                        shadow_touched_elements.email && (
                          <Box fontSize="md">
                            <Flex mt="3">
                              <Icon
                                as={MdOutlineNotificationImportant}
                                w={3.5}
                                h={3.5}
                                color="red.300"
                              />
                              <Text
                                fontSize="sm"
                                fontStyle="italic"
                                fontWeight="light"
                                color="red.600"
                                pl="3"
                              >
                                {capitalizeFirstLetter(
                                  messages[
                                    "confirm_email_doesnt_match_email_field"
                                  ] || "confirm email doesn't match email field"
                                )}
                              </Text>
                            </Flex>
                          </Box>
                        )}
                    </Flex>
                  </Flex>

                  <Flex
                    direction={{ base: "column", sm: "column", lg: "row" }}
                    mb="8"
                  >
                    <Box
                      flex="1"
                      mt="3"
                      bg={bgCardsColor}
                      boxShadow="lg"
                      rounded="6"
                      p="3"
                    >
                      <Box fontSize="md">
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["mobile_phone_number"] ||
                                "mobile phone number"
                            )}{" "}
                            *
                          </Heading>
                          <Spacer />

                          {shadow_touched_elements.pure_phone_number &&
                          (validation.pure_phone_number === -1 ||
                            validation.pure_phone_number === -2) ? (
                            <Icon
                              as={BiErrorCircle}
                              fontSize={16}
                              color="red.500"
                            />
                          ) : !shadow_touched_elements.pure_phone_number ? (
                            <Icon
                              as={BsPenFill}
                              fontSize={16}
                              color="blue.500"
                            />
                          ) : (
                            <Icon
                              as={BsCheck2Circle}
                              fontSize={16}
                              color="green.500"
                            />
                          )}
                        </Flex>

                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                        <InputGroup style={{ direction: "ltr" }}>
                          <Flex
                            px="2"
                            pt="1.5"
                            pb=".5"
                            borderWidth="1px"
                            borderRight="0"
                            borderColor="gray.200"
                          >
                            <Icon
                              as={BiWorld}
                              w={3.5}
                              h={3.5}
                              color="gray.300"
                            />
                          </Flex>
                          <Flex flex="1">
                            <PhonesSelectContainer
                              defaultValueCode={code_country_phone_number}
                              set_={set_code_country_phone_number}
                            />
                            <Input
                              onBlur={() => {
                                let tmp = { ...shadow_touched_elements };
                                tmp.pure_phone_number = true;
                                set_shadow_touched_elements(tmp);
                                set_pure_phone_number(
                                  pure_phone_number
                                    .replace(/\s+/g, " ")
                                    .replace(/'|"/g, "’")
                                    .trim()
                                );
                              }}
                              value={pure_phone_number}
                              type="number"
                              onChange={(e) =>
                                set_pure_phone_number(e.target.value)
                              }
                              placeholder="484280803"
                              size="sm"
                            />
                          </Flex>
                        </InputGroup>
                      </Box>
                      {shadow_touched_elements.pure_phone_number &&
                        validation.pure_phone_number === -1 && (
                          <Box fontSize="md">
                            <Flex mt="3">
                              <Icon
                                as={MdOutlineNotificationImportant}
                                w={3.5}
                                h={3.5}
                                color="red.300"
                              />
                              <Text
                                fontSize="sm"
                                fontStyle="italic"
                                fontWeight="light"
                                color="red.600"
                                pl="3"
                              >
                                {capitalizeFirstLetter(
                                  messages[
                                    "please_provide_a_valid_mobile_phone_number"
                                  ] ||
                                    "please provide a valide mobile phone number"
                                )}
                              </Text>
                            </Flex>
                          </Box>
                        )}
                    </Box>
                  </Flex>
                </Box>
              </GridItem>
              <GridItem
                colSpan={{
                  base: 3,
                  md: 3,
                  lg: 2,
                  xl: 3,
                }}
              >
                <Heading mt="32px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(
                    messages["vehicle_information"] || "vehicle information"
                  )}
                </Heading>

                <Box>
                  <Flex direction="column">
                    <Box
                      flex="1"
                      bg={bgCardsColor}
                      boxShadow="lg"
                      rounded="6"
                      p="3"
                    >
                      <Box fontSize="md">
                        <Flex>
                          <Heading size="xs" mb="2">
                            {capitalizeFirstLetter(
                              messages["number_of_people"] || "number of people"
                            )}
                          </Heading>
                          <Spacer />

                          <Icon
                            as={BsCheck2Circle}
                            fontSize={16}
                            color="green.500"
                          />
                        </Flex>

                        <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                        <Box>
                          <InputGroup style={{ direction: "ltr" }}>
                            <Flex
                              px="2"
                              pt="1.5"
                              pb=".5"
                              borderWidth="1px"
                              borderRight="0"
                              borderColor="gray.200"
                            >
                              <Icon
                                as={HiUserGroup}
                                w={3.5}
                                h={3.5}
                                color="gray.300"
                              />
                            </Flex>
                            <Flex flex="1">
                              <Select
                                defaultValue={number_of_people}
                                onChange={(e) =>
                                  set_number_of_people(+e.target.value)
                                }
                                size="sm"
                                style={{ direction: "ltr" }}
                              >
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((numb) => (
                                  <option key={numb} value={numb}>
                                    {numb}
                                  </option>
                                ))}
                              </Select>
                            </Flex>
                          </InputGroup>
                          {number_of_people > 4 && (
                            <Flex mt="3">
                              <Icon
                                as={MdInfoOutline}
                                w={3.5}
                                h={3.5}
                                color="blue.300"
                              />
                              <Text
                                fontSize="sm"
                                fontStyle="italic"
                                fontWeight="light"
                                color="blue.600"
                                pl={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "3"
                                }
                                pr={
                                  document.body.style.direction !== "rtl"
                                    ? "0"
                                    : "3"
                                }
                              >
                                {capitalizeFirstLetter(
                                  messages["for_bookings_beyond_5"] ||
                                    "for bookings beyond 5 children's and baby passengers included a supplement will be asked for 10 euro by passenger in addition."
                                )}
                              </Text>
                            </Flex>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Flex
                      direction={{ base: "column", "2xl": "row" }}
                      mb="8"
                      mt="3"
                      boxShadow="lg"
                      bg={bgCardsColor}
                      rounded="6"
                      p="3"
                    >
                      <Box
                        pl={
                          document.body.style.direction === "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pr={
                          document.body.style.direction !== "rtl"
                            ? { base: "0", sm: "0", lg: "3" }
                            : { base: "0", sm: "0", lg: "0" }
                        }
                        pb={{ base: "6", sm: "6", lg: "0" }}
                        flex="1"
                        fontSize="md"
                      >
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["make_and_model"] || "make and model"
                              )}
                            </Heading>
                            <Spacer />
                            {true && validation.make_and_model === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={AiOutlineCar}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.make_and_model = true;
                                  set_shadow_touched_elements(tmp);
                                  set_make_and_model(
                                    make_and_model
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                value={make_and_model}
                                onChange={(e) =>
                                  set_make_and_model(e.target.value)
                                }
                                placeholder={`${new Date().getFullYear()} Car Model LX`}
                                size="sm"
                              />
                            </InputGroup>
                          </Box>
                          <Flex mt="3">
                            <Icon
                              as={MdInfoOutline}
                              w={3.5}
                              h={3.5}
                              color="blue.300"
                            />
                            <Text
                              fontSize="sm"
                              fontStyle="italic"
                              fontWeight="light"
                              color="blue.600"
                              pl={
                                document.body.style.direction === "rtl"
                                  ? "0"
                                  : "3"
                              }
                              pr={
                                document.body.style.direction !== "rtl"
                                  ? "0"
                                  : "3"
                              }
                            >
                              {capitalizeFirstLetter(
                                messages["maximum_vehicle_height_1_90_m"] ||
                                  "maximum vehicle height: 1.80m"
                              )}
                            </Text>
                          </Flex>

                          {validation.make_and_model === -1 && (
                            <Box fontSize="md">
                              <Flex mt="3">
                                <Icon
                                  as={MdOutlineNotificationImportant}
                                  w={3.5}
                                  h={3.5}
                                  color="red.300"
                                />
                                <Text
                                  fontSize="sm"
                                  fontStyle="italic"
                                  fontWeight="light"
                                  color="red.600"
                                  pl="3"
                                >
                                  {capitalizeFirstLetter(
                                    messages[
                                      "the_length_must_be_between_3_and_64_characters_or_empty"
                                    ] ||
                                      "the length must be between 3 and 64 characters or empty."
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box flex="1" fontSize="md">
                        <Box fontSize="md">
                          <Flex>
                            <Heading size="xs" mb="2">
                              {capitalizeFirstLetter(
                                messages["license_plate_number"] ||
                                  "license plate number"
                              )}
                            </Heading>
                            <Spacer />
                            {true && validation.license_plate_number === -1 ? (
                              <Icon
                                as={BiErrorCircle}
                                fontSize={16}
                                color="red.500"
                              />
                            ) : (
                              <Icon
                                as={BsCheck2Circle}
                                fontSize={16}
                                color="green.500"
                              />
                            )}
                          </Flex>

                          <Divider
                            bg="blue.500"
                            mb="3"
                            h=".9"
                            variant="dashed"
                          />
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                right={
                                  document.body.style.direction === "rtl"
                                    ? "0"
                                    : "unset"
                                }
                                size="sm"
                                pb="2"
                                pointerEvents="none"
                                children={
                                  <Icon
                                    as={AiOutlineFieldNumber}
                                    w={3.5}
                                    h={3.5}
                                    color="gray.300"
                                  />
                                }
                              />
                              <Input
                                onBlur={() => {
                                  let tmp = { ...shadow_touched_elements };
                                  tmp.license_plate_number = true;
                                  set_shadow_touched_elements(tmp);
                                  set_license_plate_number(
                                    license_plate_number
                                      .replace(/\s+/g, " ")
                                      .replace(/'|"/g, "’")
                                      .trim()
                                  );
                                }}
                                value={license_plate_number}
                                onChange={(e) =>
                                  set_license_plate_number(e.target.value)
                                }
                                placeholder="A123 XYZ"
                                size="sm"
                              />
                            </InputGroup>
                            {validation.license_plate_number === -1 && (
                              <Box fontSize="md">
                                <Flex mt="3">
                                  <Icon
                                    as={MdOutlineNotificationImportant}
                                    w={3.5}
                                    h={3.5}
                                    color="red.300"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontStyle="italic"
                                    fontWeight="light"
                                    color="red.600"
                                    pl="3"
                                  >
                                    {capitalizeFirstLetter(
                                      messages[
                                        "the_length_must_be_between_3_and_64_characters_or_empty"
                                      ] ||
                                        "the length must be between 3 and 64 characters or empty."
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>

                <Box mb="8" bg={bgCardsColor} boxShadow="lg" rounded="6" p="3">
                  <Box fontSize="md">
                    <Flex>
                      <Heading size="xs" mb="2">
                        {capitalizeFirstLetter(
                          messages["your_reservation"] || "your reservation"
                        )}
                        {" *"}
                      </Heading>
                      <Spacer />
                      {validation.start_date === -1 ||
                      validation.start_time === -1 ||
                      validation.end_date === -1 ||
                      validation.end_time === -1 ? (
                        <Icon
                          as={BiErrorCircle}
                          fontSize={16}
                          color="red.500"
                        />
                      ) : (
                        <Icon
                          as={BsCheck2Circle}
                          fontSize={16}
                          color="green.500"
                        />
                      )}
                    </Flex>

                    <Divider bg="blue.500" mb="3" h=".9" variant="dashed" />
                    <Box>
                      <b>
                        {days_in_parking}{" "}
                        {days_in_parking > 1
                          ? messages["days"] || "days"
                          : messages["day"] || "day"}
                      </b>{" "}
                      {messages["shuttle_service"] || "shuttle service"} ({" "}
                      <b>{price}€</b> ) : <br />
                      <b>
                        {moment(start_date)
                          .locale(lang)
                          .format("dddd DD MMM YYYY")}
                        {" - "}
                        {start_time[0] === "0"
                          ? start_time.substring(1, 5)
                          : start_time}
                      </b>{" "}
                      {messages["to"] || "to"}{" "}
                      <b>
                        {moment(end_date)
                          .locale(lang)
                          .format("dddd DD MMM YYYY")}
                        {" - "}
                        {end_time[0] === "0"
                          ? end_time.substring(1, 5)
                          : end_time}
                      </b>
                    </Box>
                  </Box>
                </Box>

                <Heading mt="32px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(
                    messages["extra_products"] || "extra products"
                  )}
                </Heading>
                <Box>
                  <Flex
                    rounded="6"
                    bg={bgCardsColor}
                    boxShadow="lg"
                    p="3"
                    direction="column"
                  >
                    {products &&
                      products.map((product: any, index: number) => (
                        <Flex
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                          rounded="5"
                          key={`key-${index}`}
                          mb="2"
                          px="3"
                          py="2"
                          w="100%"
                          justifyContent="space-between"
                          borderColor="blue.500"
                          borderWidth="1px"
                          boxShadow="md"
                          as="label"
                          htmlFor={`product-${index}`}
                        >
                          <Flex>
                            <Checkbox
                              onChange={() => {
                                const currentObj = {
                                  ...getItemByIdentifier(
                                    product.id,
                                    products,
                                    "id"
                                  ),
                                };

                                const newUpdate: {
                                  id: string;
                                  name: string;
                                  price: string;
                                  state: string;
                                } = {
                                  id: currentObj.id,
                                  name: currentObj.name,
                                  price: currentObj.price,
                                  state:
                                    currentObj.state === "yes" ? "no" : "yes",
                                };

                                const newUpdatedObj = updateObject(
                                  newUpdate,
                                  products,
                                  "id"
                                );

                                set_products([...newUpdatedObj]);
                              }}
                              id={`product-${index}`}
                              isChecked={
                                getAttrOfItemByIdentifier(
                                  product.id,
                                  products,
                                  "state",
                                  "id"
                                ) === "yes"
                                  ? true
                                  : false
                              }
                            />
                          </Flex>
                          <Flex>{JSON.parse(product.name)[lang]}</Flex>
                          <Flex>
                            {+product.price === 0
                              ? messages["free"] || "free"
                              : product.price}
                          </Flex>
                        </Flex>
                      ))}
                  </Flex>
                </Box>

                <Heading mt="48px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(
                    messages["payment_on_the_spot"] || "payment on the spot"
                  )}
                </Heading>
                <Box>
                  <Flex
                    rounded="6"
                    bg={bgCardsColor}
                    boxShadow="lg"
                    p="3"
                    direction="column"
                  >
                    <Flex
                      style={{ cursor: "pointer" }}
                      rounded="5"
                      px="3"
                      py="1"
                      w="100%"
                      justifyContent="space-between"
                      borderColor="blue.500"
                      borderWidth="1px"
                      boxShadow="md"
                      as="label"
                      htmlFor={`product`}
                      alignItems="center"
                    >
                      <Flex>
                        <Avatar
                          size="sm"
                          bg={bgCardsColor}
                          rounded="3"
                          src="/img/pay.png"
                        />
                      </Flex>
                      <Flex>
                        {capitalizeFirstLetter(
                          messages["cash_payment_on_site"] ||
                            "cash payment on site"
                        )}
                      </Flex>
                      <Flex></Flex>
                    </Flex>
                  </Flex>
                </Box>
              </GridItem>
              <GridItem
                colSpan={{
                  base: 0,
                  md: 0,
                  lg: 1,
                  xl: 0,
                }}
                display={{
                  base: "none",
                  md: "none",
                  lg: "block",
                  xl: "none",
                }}
              ></GridItem>
              <GridItem
                colSpan={{
                  base: 3,
                  md: 3,
                  lg: 2,
                  xl: 3,
                }}
              >
                <Heading mt="32px" mb="3" fontSize="2xl">
                  {capitalizeFirstLetter(messages["validate"] || "validate")}
                </Heading>
                <Divider />
                <Box>
                  <Flex
                    flex="1"
                    bg={bgCardsColor}
                    boxShadow="lg"
                    rounded="6"
                    direction="column"
                  >
                    <Flex p="3" roundedTop="6" bg="blue.200">
                      <Heading size="sm">
                        {capitalizeFirstLetter(messages["price"] || "price")}
                      </Heading>
                    </Flex>

                    <Flex p="3" direction="column">
                      <Flex justifyContent="space-between">
                        <Box>
                          <b>
                            {days_in_parking}{" "}
                            {days_in_parking > 1
                              ? messages["days"] || "days"
                              : messages["day"] || "day"}
                          </b>{" "}
                          {messages["shuttle_service"] || "shuttle service"}
                        </Box>
                        <Flex>{price}€</Flex>
                      </Flex>
                    </Flex>

                    {active_products.map((elm: any) => {
                      if (elm.id === "sup-8-eruo") {
                        return (
                          <Flex key={elm.id} p="3" direction="column">
                            <Flex justifyContent="space-between">
                              <Box>{elm.name}</Box>
                              <Flex>{elm.price}€</Flex>
                            </Flex>
                          </Flex>
                        );
                      } else {
                        return (
                          <Flex key={elm.id} p="3" direction="column">
                            <Flex justifyContent="space-between">
                              <Box>{JSON.parse(elm.name)[lang]}</Box>
                              <Flex>{elm.price}€</Flex>
                            </Flex>
                          </Flex>
                        );
                      }
                    })}

                    <Divider bg="gray.100" h="2px" mb="3" />
                    <Flex p="3" justifyContent="space-between">
                      <Flex>
                        {capitalizeFirstLetter(
                          messages["its_time_to_complete_the_reservation"] ||
                            "it's time to complete the reservation"
                        )}
                      </Flex>
                      <Flex>
                        <span id="down_counter_reservation">30:00</span>
                      </Flex>
                    </Flex>
                    <Flex
                      p="3"
                      fontWeight="bold"
                      bg="blue.400"
                      color="white"
                      justifyContent="space-between"
                    >
                      <Flex>
                        {capitalizeFirstLetter(messages["total"] || "total")}
                      </Flex>
                      <Flex>{+price + +products_price}€</Flex>
                    </Flex>

                    <Flex mt="3" flex="1" p="3" direction="column">
                      <Flex
                        color={
                          !accept_conditions &&
                          shadow_touched_elements.accept_conditions
                            ? "red.500"
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                        as="label"
                        htmlFor="gc-check"
                        mb="3"
                      >
                        <Checkbox
                          checked={accept_conditions}
                          onChange={(e) => {
                            set_accept_conditions(e.target.checked);
                          }}
                          mr={
                            document.body.style.direction === "rtl" ? "0" : "3"
                          }
                          ml={
                            document.body.style.direction !== "rtl" ? "0" : "3"
                          }
                          id="gc-check"
                        />{" "}
                        {capitalizeFirstLetter(
                          messages["i_accept_the_general_conditions_of"] ||
                            "i accept the general conditions of"
                        )}{" "}
                        {config.APP_NAME}
                      </Flex>
                      <Flex
                        color={
                          !check_details &&
                          shadow_touched_elements.check_details
                            ? "red.500"
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                        as="label"
                        htmlFor="res-check"
                        mb="3"
                      >
                        <Checkbox
                          checked={check_details}
                          onChange={(e) => {
                            set_check_details(e.target.checked);
                          }}
                          mr={
                            document.body.style.direction === "rtl" ? "0" : "3"
                          }
                          ml={
                            document.body.style.direction !== "rtl" ? "0" : "3"
                          }
                          id="res-check"
                        />{" "}
                        {capitalizeFirstLetter(
                          messages[
                            "i_checked_my_information_and_the_details_of_the_reservation"
                          ] ||
                            "i checked my information and the details of the reservation"
                        )}
                      </Flex>

                      <Flex mt="3">
                        <Spacer />
                        <Button
                          disabled={
                            is_submited || reservateLoading || get_price_loading
                          }
                          onClick={onSubmitHandler}
                          colorScheme="blue"
                        >
                          {capitalizeFirstLetter(
                            messages["complete_reservation"] ||
                              "complete reservation"
                          )}
                        </Button>
                      </Flex>
                      <Flex mt="3">
                        <Spacer />
                        <Text>
                          {capitalizeFirstLetter(
                            messages["cancel_for_free"] || "cancel for free"
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
                {/* <Box p="3"  bg={bgCardsColor} mt="5">
                  <Flex>
                    <Heading size="md">Just for DEBUGING</Heading>
                  </Flex>
                  <pre>{JSON.stringify(info, null, 2)}</pre>
                </Box> */}
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Flex
        display={{
          base: "flex",
          sm: "flex",
          md: "flex",
          lg: "none",
          xl: "none",
        }}
        w={{
          base: "95%",
          sm: "95%",
          md: "55%",
          lg: "0",
          xl: "0",
        }}
        zIndex={5555}
        position="fixed"
        right="0"
        flex="1"
        bg="transparent"
        rounded="0"
        transform={
          !mobile_total_widget ? "translateX(91%) translateY(30%)" : ""
        }
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          w="9%"
        >
          <Box
            boxShadow="2xl"
            onClick={() => set_mobile_total_widget(!mobile_total_widget)}
            p="4px"
            w="100%"
            bg="blue.200"
          >
            <Icon
              as={
                mobile_total_widget ? MdKeyboardArrowRight : MdKeyboardArrowLeft
              }
              w="6"
              h="6"
              color="white"
            />
          </Box>
        </Flex>
        <Flex direction="column" w="91%">
          <Flex p="3" roundedTopLeft="6" bg="blue.200">
            <Heading size="sm">
              {capitalizeFirstLetter(messages["price"] || "price")}
            </Heading>
          </Flex>

          <Flex bg={bgCardsColor} p="3" direction="column">
            <Flex justifyContent="space-between">
              <Box>
                <b>
                  {days_in_parking}{" "}
                  {days_in_parking > 1
                    ? messages["days"] || "days"
                    : messages["day"] || "day"}
                </b>{" "}
                {messages["shuttle_service"] || "shuttle service"}
              </Box>
              <Flex>{price}€</Flex>
            </Flex>
          </Flex>

          {active_products.map((elm: any) => {
            if (elm.id === "sup-8-eruo") {
              return (
                <Flex key={elm.id} p="3" direction="column">
                  <Flex justifyContent="space-between">
                    <Box>{elm.name}</Box>
                    <Flex>{elm.price}€</Flex>
                  </Flex>
                </Flex>
              );
            } else {
              return (
                <Flex key={elm.id} p="3" direction="column">
                  <Flex justifyContent="space-between">
                    <Box>{JSON.parse(elm.name)[lang]}</Box>
                    <Flex>{elm.price}€</Flex>
                  </Flex>
                </Flex>
              );
            }
          })}

          <Flex
            roundedBottomLeft="6"
            p="3"
            fontWeight="bold"
            bg="blue.400"
            color="white"
            justifyContent="space-between"
          >
            <Flex>{capitalizeFirstLetter(messages["total"] || "total")}</Flex>
            <Flex>{+price + +products_price}€</Flex>
          </Flex>
        </Flex>
      </Flex>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onErrorDialogClose}
        isOpen={isErrorDialogOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            <span>
              {capitalizeFirstLetter(
                messages["please_provide_a_valid_data"] ||
                  "please provide a valid data"
              )}{" "}
              :
            </span>
            <span>
              <AlertDialogCloseButton
                left={document.body.style.direction === "rtl" ? "0" : "unset"}
                right={document.body.style.direction === "rtl" ? "unset" : "0"}
              />
            </span>
          </AlertDialogHeader>

          <AlertDialogBody>
            {invalid_elements &&
              invalid_elements.map((el, index) => (
                <Flex key={`${index}-invalid`}>
                  <span>-</span>
                  <span>
                    &nbsp; <b>{messages[`${correctNames(el)}`]}</b> &nbsp;
                  </span>
                  <span>
                    ({capitalizeFirstLetter(messages["invalid"] || "invalid")})
                  </span>
                </Flex>
              ))}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              ref={cancelRef}
              onClick={onErrorDialogClose}
            >
              {capitalizeFirstLetter(messages["ok"] || "ok")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ReservateComponent;

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box mr="3" as="label">
      <input {...input} />
      <Box
        className="ripple"
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "blue.600",
          color: "white",
          borderColor: "blue.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
}
