import React from "react";
import { Flex, Box } from "@chakra-ui/react";

// Utils
import Navbar from "./Navbar";
import ParkingInfoLangContainer from "./Navbar/ParkingInfo/containers/lang";
import FooterLangContainer from "./Footer/containers/lang";

// Props
interface appLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<appLayoutProps> = ({ children }) => {

  return (
    <Box>
      <Navbar />
      <Box className="display-print-none" w="100%" h="60px"></Box>
      <Flex
        className="display-print-none"
        zIndex={6}
        justifyContent="center"
        alignItems="center"
        py="5"
        display={{
          base: "flex",
          sm: "flex",
          md: "flex",
          lg: "none",
          xl: "none",
        }}
      >
        <ParkingInfoLangContainer />
      </Flex>
      <Box minH="61vh">{children}</Box>
      <FooterLangContainer />
    </Box>
  );
};

export default AppLayout;
