const is_prod = process.env.NODE_ENV === "production";

export const config = {
  APP_NAME: "Aeropark1000",
  APP_HOME_URL: is_prod
    ? "https://www.aeropark1000.com"
    : "http://localhost:4405",
  DEFAULT_LANG: "fr",
  EP: is_prod
    ? "https://front-shield.aeropark1000.com/graphql"
    : "http://localhost:4403/graphql",
  C_J_K: is_prod ? "cGFzc2oduTU0OTg2Cg==" : "cGFzc2oduTU0OTx7zf9Fzf63f",
};
